import { Button, Icon, SectionTitle } from '@pinflag/pinflag-ui-kit'
import React, { useContext } from 'react'
import { DataContext } from '../context/DataContext'

const ReturnPickupPoint = () => {
  const {
    selectedShippingMethod,
    setSelectedShippingMethod,
    steps,
    setSteps,
    setCurrentStep,
    companyInfo,
    setIsPickup,
    setDeliveryInfo,
    setPointsData
  } = useContext(DataContext)

  const deliveryMethod = [
    {
      type: 'local-pickup',
      name: 'Retiro en Tienda'
    },
    {
      type: 'click-and-collect',
      name: 'Retiro en Punto'
    },
    {
      type: 'home-delivery',
      name: 'Despacho a Domicilio'
    }
  ]

  const handleBack = () => {
    setIsPickup(false)
    setSelectedShippingMethod(null)
    setPointsData((prevState) => {
      prevState.localPointSelected = {}
      prevState.pinflagPointSelected = {}
      return prevState
    })
    const updatedSteps = [...steps]
    updatedSteps[0].completed = true
    updatedSteps[1].disabled = false
    updatedSteps[1].subSteps[0].completed = false
    updatedSteps[1].subSteps[1].completed = false
    updatedSteps[1].subSteps[2].completed = false
    setSteps(updatedSteps)
    setCurrentStep(2)
    setDeliveryInfo({})
  }

  return (
    <div className=' flex w-full md:px-8 px-6'>
      <div className='flex flex-row w-full py-2 rounded items-center justify-between'>
        <SectionTitle
          className=''
          title='Modo entrega:'
          iconName='truck'
          iconCustomStyle={{ fill: 'transparent', stroke: 'white' }}
          primaryColor={companyInfo.primaryColor}
        />
        <div className='flex sm:px-5 text-gray-500 text-xs md:min-w-[190px]'>
          {deliveryMethod.map((method, index) => {
            return (
              method.type === selectedShippingMethod && (
                <p key={index}>{method.name}</p>
              )
            )
          })}
        </div>
        <Button
          label='Cambiar'
          LeftIcon={<Icon name='edit' className='fill-gray-800 mr-2' width={9} />}
          onClick={handleBack}
          className='flex items-center bg-slate-100 px-4 py-1 rounded-2xl text-[11px] font-light h-[23px] w-auto'
          testId='change-delivery'
        />
      </div>
    </div>
  )
}

export default ReturnPickupPoint
