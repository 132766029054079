import axios from 'axios'
import { BOT_URL } from '../constants/url'

export const sentBotMessage = (message, subMessage) => {
  axios
    .post(`${BOT_URL}/message`, {
      origin: 'Pinmap pro',
      message,
      sub_message: subMessage,
      type: 'checkoutError'
    })
    .catch(err => {
      console.error(err)
    })
}

export const errorMessage = (errorResponse, companyInfo, endpoint, body) => {
  const { companyName } = companyInfo
  let message = `Error: ${errorResponse?.message}\n`
  message += `Message: error calling ${endpoint}\n`
  message += `Company: ${companyName}\n`
  let subMessage = `${JSON.stringify(errorResponse)}\n`
  subMessage += body ? JSON.stringify(body) : ''
  sentBotMessage(message, subMessage)
}
