import { useContext } from 'react'
import { DataContext } from '../context/DataContext'
import _ from 'lodash'
import {
  abreviatedCartLines,
  itemsUrlParser,
  totalPriceParser
} from '../helpers/utils'
import {
  billingUrlParser,
  shippingAddressObject,
  shippingAddressParser
} from '../helpers/addressParser'
import { numberParser } from '../helpers/numberParser'
import { STORES_REDIRECT_SHIPPING_STEP, STORES_STOCKABLES, STORES_WITH_THIRD_PARTY_RATES } from '../constants/stores'
import { sentBotMessage } from '../helpers/bot'
import { useCmsAdapter } from './useCmsAdapter'
import { checkLocations, matchProductsLocations } from '../helpers/filterPoints'
import { gaOpenedPinmapPostMessage } from '../helpers/gaEventsHelper'

export const useShopify = () => {
  const {
    setCartData,
    addressInfo,
    userInfo,
    setEcommerceInfo,
    ecommerceInfo,
    companyInfo,
    setUserInfo,
    setAddressInfo,
    billingInfo,
    cartData,
    setShopifyLocations
  } = useContext(DataContext)

  const {
    getCheckout,
    createCheckout,
    setItemsCheckout,
    updateEmailCheckout,
    updateAddressCheckout,
    getShippingRates,
    setShippingRate,
    getPaymentUrl,
    deleteCheckoutLog,
    getVariantLocations,
    availableShippingRates
  } = useCmsAdapter()

  const setInitialInformationShopify = (message, origin) => {
    if (message.cms !== 'shopify') return
    gaOpenedPinmapPostMessage()
    const {
      cms,
      checkoutId,
      cartItems,
      currentUrl,
      totalWeight,
      loggedIn,
      customerData
    } = message

    if (loggedIn) {
      const {
        names,
        email,
        phone,
        rut,
        userAddress,
        aditionalInfo,
        region,
        district
      } = customerData
      setUserInfo(prevState => {
        return {
          ...prevState,
          email,
          names,
          phone,
          rut
        }
      })
      setAddressInfo(prevState => {
        return {
          ...prevState,
          userAddress,
          aditionalInfo,
          region,
          district
        }
      })
    }

    setEcommerceInfo(prevState => {
      return {
        ...prevState,
        cms,
        checkoutId,
        currentUrl,
        domain: origin
      }
    })

    if (cartItems.length === 0) {
      reloadPage()
      return
    }

    const cartProducts = cartItems.map(product => {
      return {
        id: product.id,
        variantId: product.variant_id,
        productImage: product.image,
        productName: product.product_title,
        quantity: product.quantity,
        lineTotal: numberParser(product.final_price / 100),
        final_line_price: product.final_line_price
      }
    })
    setCartData(prevState => {
      return {
        ...prevState,
        total: totalPriceParser(cartItems),
        subtotal: totalPriceParser(cartItems),
        lines: cartProducts,
        itemsCount: cartProducts.reduce(
          (sum, { quantity }) => sum + quantity,
          0
        ),
        totalWeight: Math.ceil(totalWeight / 1000)
      }
    })
  }

  const reloadPage = () => {
    window.parent.postMessage(
      {
        type: 'reload-page',
        origin: 'pinflag-shopify-pinmap-pro',
        message: true
      },
      '*'
    )
  }

  const removeIframeShopify = (extraUserInfo = {}, extraAddressInfo = {}) => {
    if (ecommerceInfo.cms !== 'shopify') return
    const permalink = createPermalink(extraUserInfo, extraAddressInfo)
    window.parent.postMessage(
      {
        type: 'redirect-to-checkout',
        origin: 'pinflag-shopify-pinmap-pro',
        message: permalink
      },
      '*'
    )
  }

  const redirectToHomeShopify = () => {
    if (ecommerceInfo.cms !== 'shopify') return
    window.parent.postMessage(
      {
        type: 'redirect-to-home',
        origin: 'pinflag-shopify-pinmap-pro',
        message: true
      },
      '*'
    )
  }

  const redirectToCartShopify = () => {
    if (ecommerceInfo.cms !== 'shopify') return
    window.parent.postMessage(
      {
        type: 'redirect-to-cart',
        origin: 'pinflag-shopify-pinmap-pro',
        message: true
      },
      '*'
    )
  }

  const loadCheckout = () => {
    if (ecommerceInfo.cms !== 'shopify') return
    const { checkoutId } = ecommerceInfo
    getCheckout(checkoutId)
      .then(res => {
        if (res.data.checkout === null || res.data.checkout.completedAt !== null) return

        const { id, email, lineItems, orderStatusUrl } = res.data.checkout

        if (lineItems?.edges?.some(line => line.node.variant === null)) return

        const parsedLineItems = lineItems?.edges?.map(line => {
          return {
            quantity: line.node.quantity,
            variantId: line.node.variant.id,
            id: line.node.id
          }
        })

        setEcommerceInfo(prevState => {
          return {
            ...prevState,
            currentCheckout: {
              id,
              email,
              lineItems: parsedLineItems,
              orderStatusUrl
            }
          }
        })
      })
      .catch(err => {
        removeIframeShopify()
        console.log(err)
      })
  }

  const evaluateCartsEquity = (cart1, cart2) => {
    return _.isEmpty(_.xorWith(cart1, cart2, _.isEqual))
  }

  const handleUserInformationShopify = async userData => {
    // IF currentCheckout.orderStatusUrl !== null --> Order is already completed
    // Reference: https://shopify.dev/api/storefront/2022-07/objects/Checkout
    if (ecommerceInfo.cms !== 'shopify') return
    const { checkoutId, currentCheckout } = ecommerceInfo
    if (
      userData?.email === currentCheckout?.email &&
      checkoutId === currentCheckout?.id &&
      !currentCheckout?.orderStatusUrl
    ) {
      const proceedWithExistingEmail = async id => {
        const cartsAreEquals = evaluateCartsEquity(
          abreviatedCartLines(cartData.lines),
          currentCheckout.lineItems.map(l => {
            return { variantId: l.variantId, quantity: l.quantity }
          })
        )
        if (!cartsAreEquals) {
          try {
            const oldLineItemsId = currentCheckout.lineItems.map(l => l.id)
            const newLineItems = abreviatedCartLines(cartData.lines)
            const response = await setItemsCheckout(
              id,
              oldLineItemsId,
              newLineItems
            )
            const { checkoutUserErrors } = response.data
            if (checkoutUserErrors.length) removeIframeShopify(userData)
          } catch (error) {
            removeIframeShopify(userData)
            console.log(error)
          }
        }
      }
      const existingEmail = await proceedWithExistingEmail(checkoutId)
      return existingEmail
    }

    // Proceed with new checkout if checkout id does not exists or currentCheckout is already complete
    if (!currentCheckout?.id || currentCheckout?.orderStatusUrl) {
      const proceedWithNewCheckout = async formData => {
        const { email } = formData
        const input = {
          email,
          lineItems: await abreviatedCartLines(cartData.lines)
        }
        try {
          const response = await createCheckout(input)
          const { checkout, checkoutUserErrors } = response.data
          if (checkoutUserErrors.length) {
            if (
              checkoutUserErrors.length > 1 ||
              checkoutUserErrors[0].code !== 'BAD_DOMAIN'
            ) { return removeIframeShopify(formData) }

            return { error: checkoutUserErrors[0] }
          }
          setEcommerceInfo(prevState => {
            return {
              ...prevState,
              checkoutId: checkout.id
            }
          })
          window.parent.postMessage(
            {
              type: 'receive-checkout-id',
              origin: 'pinflag-shopify-pinmap-pro',
              message: checkout.id
            },
            '*'
          )
        } catch (err) {
          console.log(err)
        }
      }
      const newCheckout = await proceedWithNewCheckout(userData)
      return newCheckout
    }

    const proceedWithUpdatedEmail = async userData => {
      try {
        const responseUpdateEmail = await updateEmailCheckout(
          checkoutId,
          userData.email
        )
        const { checkout, checkoutUserErrors } = responseUpdateEmail.data
        if (checkoutUserErrors.length) {
          if (
            checkoutUserErrors.length > 1 ||
            checkoutUserErrors[0].code !== 'BAD_DOMAIN'
          ) { return removeIframeShopify(userData) }

          return { error: checkoutUserErrors[0] }
        }
        const { id } = checkout
        window.parent.postMessage(
          {
            type: 'receive-checkout-id',
            origin: 'pinflag-shopify-pinmap-pro',
            message: id
          },
          '*'
        )
        setEcommerceInfo(prevState => {
          return { ...prevState, checkoutId: id }
        })
        const oldLineItemsId = currentCheckout.lineItems.map(l => l.id)
        const newLineItems = abreviatedCartLines(cartData.lines)
        const responseSetItems = await setItemsCheckout(
          id,
          oldLineItemsId,
          newLineItems
        )
        const { checkoutUserErrors: errorsAddItems } = responseSetItems.data
        if (errorsAddItems.length) removeIframeShopify(userData)
      } catch (err) {
        console.log(err)
      }
    }
    const updatedEmail = await proceedWithUpdatedEmail(userData)
    return updatedEmail
  }

  const iframeLoadedShopify = (isCorrect) => {
    window.parent.postMessage(
      {
        type: 'iframe-loaded',
        origin: 'pinflag-shopify-pinmap-pro',
        message: isCorrect
      },
      '*'
    )
  }

  const saveShippingInformationShopify = async () => {
    const { companyName } = companyInfo
    const { cms, checkoutId } = ecommerceInfo
    if (cms !== 'shopify') return

    const shippingAddress = shippingAddressObject(
      userInfo,
      addressInfo,
      companyInfo
    )
    try {
      await updateAddressCheckout(checkoutId, shippingAddress)
      const response = await getShippingRates(checkoutId)
      const { availableShippingRates, webUrl } = response.data.checkout
      if (availableShippingRates && availableShippingRates.ready) {
        const { shippingRates } = availableShippingRates
        const pinflagShippingRate = shippingRates
          ? shippingRates.find(rate => rate.handle.includes('Pinflag'))
          : undefined
        if (pinflagShippingRate) {
          handleSetShippingRates(pinflagShippingRate)
        } else {
          let message = `${companyName}: `
          message += shippingRates
            ? 'Pinflag Shipping Rate did not provide a rate'
            : 'Shopify did not provide any rate'
          sentBotMessage(message)
          const customUrl = webUrl.replace(
            '?key',
            '/shipping_rates?step=shipping_method&key'
          )
          window.parent.postMessage(
            {
              type: 'redirect-to-checkout',
              origin: 'pinflag-shopify-pinmap-pro',
              message: customUrl
            },
            '*'
          )
        }
      }
    } catch (err) {
      console.log('error handleFinish')
      removeIframeShopify()
      console.log(err)
    }
  }

  const handleSetShippingRates = shippingRate => {
    setShippingRate(ecommerceInfo.checkoutId, shippingRate.handle).then(res => {
      if (!res.data) throw new Error('Checkout null')
      const { webUrl } = res.data.checkout
      const step = STORES_REDIRECT_SHIPPING_STEP.includes(
        companyInfo.companyName
      )
        ? 'shipping'
        : 'payment'
      const customUrl = webUrl.replace(
        '?key',
        `/shipping_rates?step=${step}_method&key`
      )
      window.parent.postMessage(
        {
          type: 'redirect-to-checkout',
          origin: 'pinflag-shopify-pinmap-pro',
          message: customUrl
        },
        '*'
      )
    })
  }

  const createPermalink = (extraUserInfo, extraAddressInfo) => {
    const user = { ...userInfo, ...extraUserInfo }
    const address = { ...addressInfo, ...extraAddressInfo }
    const { email } = user
    const { domain } = ecommerceInfo
    const { serviceName } = address
    const { companyName } = companyInfo

    const shippingAddress = shippingAddressObject(user, address, companyInfo)

    if (Object.keys(cartData.lines).length === 0) return `https://${domain}/checkout`

    const baseUrl = `https://${domain}/cart/`
    const itemsUrl = itemsUrlParser(cartData.lines)
    const emailUrl = email ? `?checkout[email]=${email}` : '?'
    const shippingAddressUrl = shippingAddressParser(shippingAddress)
    const billingUrl = billingInfo.corporateName ? `&${billingUrlParser(billingInfo, companyName)}` : ''
    const rutCustomAttribute = companyName === 'Ironside' ? `&attributes[rut_cliente]=${user.rut}` : ''
    const serviceNameCustomUrl = serviceName ? `&attributes[serviceName]=${serviceName}` : ''

    return `${baseUrl}${itemsUrl}${emailUrl}${shippingAddressUrl}${billingUrl}${rutCustomAttribute}${serviceNameCustomUrl}`
  }

  const redirectPermalink = async () => {
    const { companyName } = companyInfo
    let permalink = createPermalink()
    let cookies
    // Paso 2 a 3
    try {
      if (companyName === 'Veus ') {
        const response = await getPaymentUrl(permalink)
        permalink = response.data.paymentUrl
        cookies = response.data.cookies
      }
      window.parent.postMessage(
        {
          type: 'redirect-to-checkout',
          origin: 'pinflag-shopify-pinmap-pro',
          message: { permalink, cookies }
        },
        '*'
      )
    } catch (err) {
      console.log(err)
      window.parent.postMessage(
        {
          type: 'redirect-to-checkout',
          origin: 'pinflag-shopify-pinmap-pro',
          message: { permalink, cookies }
        },
        '*'
      )
    }
  }

  const completeCheckoutLog = async (userInfo) => {
    const { email } = userInfo
    await deleteCheckoutLog(email)
  }

  const checkUpdatedCart = (message) => {
    if (message.error) {
      console.log('error', message.error)
      return
    }

    setCartData(prevState => {
      const cartProducts = message.items.map(product => {
        if (product.quantity > 0) {
          return {
            id: product.id,
            variantId: product.variant_id,
            productImage: product.image,
            productName: product.product_title,
            quantity: product.quantity,
            lineTotal: numberParser(product.final_price / 100),
            final_line_price: product.final_line_price
          }
        }
        return false
      }).filter(product => product)
      return {
        ...prevState,
        total: (message.total_price / 100),
        subtotal: (message.total_price / 100),
        lines: cartProducts,
        itemsCount: message.item_count
      }
    })
  }

  const availableShopifyLocations = async () => {
    const { cms } = ecommerceInfo
    const { companyName } = companyInfo

    if (cms !== 'shopify' || !STORES_STOCKABLES.includes(companyName)) return { disabled: false, locations: [] }

    if (cartData.lines.length > 3) {
      setShopifyLocations({ disabled: true, locations: [] })
      return { disabled: true, locations: [] }
    }
    const enabledLocations = []
    try {
      for (let index = 0; index < cartData.lines.length; index++) {
        const product = cartData.lines[index]
        const response = await getVariantLocations(product.variantId)
        const { edges } =
          response.data.data.productVariant.inventoryItem.inventoryLevels
        const points = checkLocations(edges, product.quantity)
        enabledLocations.push(points)
      }
      const locations = matchProductsLocations(enabledLocations)

      if (!locations.length) {
        setShopifyLocations({ disabled: true, locations: [] })
        return { disabled: true, locations: [] }
      }
      setShopifyLocations({
        disabled: false,
        locations
      })
      return { disabled: false, locations }
    } catch (err) {
      console.error(err)
      return { disabled: false, locations: [] }
    }
  }

  const calculateShippingRates = async (district, region, companyName) => {
    try {
      if (!STORES_WITH_THIRD_PARTY_RATES.includes(companyName)) throw new Error('Store not found')
      const response = await availableShippingRates({
        lineItems: abreviatedCartLines(cartData.lines),
        shippingAddress: shippingAddressObject(userInfo, { district, region }, companyInfo)
      })
      const { availableShippingRates: shippingRates } = response.data.calculatedDraftOrder
      return shippingRates
    } catch (error) {
      return []
    }
  }

  return {
    setInitialInformationShopify,
    handleUserInformationShopify,
    saveShippingInformationShopify,
    redirectToHomeShopify,
    loadCheckout,
    redirectToCartShopify,
    iframeLoadedShopify,
    removeIframeShopify,
    redirectPermalink,
    completeCheckoutLog,
    checkUpdatedCart,
    availableShopifyLocations,
    calculateShippingRates
  }
}
