import React, { useContext, useEffect, useState } from 'react'
import {
  CartLines,
  CartSummary,
  CommerceHeader,
  SideBar,
  Icon
} from '@pinflag/pinflag-ui-kit'
import { DataContext } from '../context/DataContext'
import { numberParser } from '../helpers/numberParser'
import { useMagento } from '../hook/useMagento'
import { useShopify } from '../hook/useShopify'

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false)

  const { cartData, isPickup, companyInfo, ecommerceInfo, currentStep } = useContext(DataContext)
  const { redirectToHomeMagento } = useMagento()
  const { redirectToHomeShopify } = useShopify()

  const handleCartIconClick = () => {
    setIsOpen(!isOpen)
  }

  const handleClosePinmap = () => {
    window.parent.postMessage(
      {
        type: 'close-iframe',
        origin: 'pinflag-shopify-pinmap-pro',
        message: true
      },
      '*'
    )
  }

  const redirectToHome = () => {
    redirectToHomeMagento()
    redirectToHomeShopify()
  }

  useEffect(() => {
    cartData.length === 0 && redirectToHomeShopify()
    cartData.lines.length === 0 && redirectToHomeShopify()
  }, [cartData])

  const updateItemQuantity = (id, quantity) => {
    const { cms } = ecommerceInfo
    if (cms !== 'shopify') return
    window.parent.postMessage(
      {
        type: 'update-cart',
        origin: 'pinflag-shopify-pinmap-pro',
        message: { key: id, quantity }
      },
      '*'
    )
  }

  return (
    <>
      <div className='h-14 mx-4 md:mx-0 md:w-[537px] mt-6 md:mt-0'>
        <CommerceHeader
          logoUrl={
            companyInfo.logoUrl !== ''
              ? companyInfo.logoUrl
              : ''
          }
          onCartIconClick={handleCartIconClick}
          onLogoClick={redirectToHome}
          primaryColor={companyInfo.primaryColor}
          closePinmap={handleClosePinmap}
        />
      </div>
      <SideBar isOpen={isOpen} isPickup={isPickup} className='bg-slate-100 md:w-[37%] md:pt-14 md:text-md z-100'>
        <div className={isPickup ? 'hidden md:flex pl-2' : 'hidden'}>
          <Icon name='shopping-cart' width={22} className='fill-slate-200 mr-5' />
        </div>
        <div className={isPickup ? 'md:hidden flex flex-col h-full' : 'flex flex-col h-full'}>
          <div className='flex text-sm font-medium justify-around mb-10 md:justify-center'>
            <img src='' alt='' />
            <div className='flex flex-raw md:text-lg text-pfblue items-center font-semibold'>
              <Icon name='shopping-cart' width={22} className='fill-slate-200 mr-5' customStyle={{ fill: companyInfo.primaryColor }} />
              Resumen de compra
            </div>
            <Icon name='close' width={20} onClick={() => setIsOpen(false)} className='md:hidden'>X</Icon>
          </div>
          <div className='grow max-h-[40%]'>
            <CartLines
              lines={cartData.lines}
              showDelete={ecommerceInfo.cms === 'shopify' && currentStep === 1}
              onClickFunction={(id, quantity) => updateItemQuantity(id, quantity)}
              height={'100%'}
            />
          </div>
          <div className='flex flex-col justify-end grow max-h-[40%] md:max-h-[50%]'>
            <hr className='mt-2 mb-2' />
            <CartSummary
              subtotal={numberParser(cartData.subtotal)}
              total={numberParser(cartData.total)}
              itemsCount={numberParser(cartData.itemsCount)}
              shippingCost={cartData.shippingCost !== null ? numberParser(cartData?.shippingCost) : null}
            />
          </div>
        </div>
      </SideBar>
    </>
  )
}
