
export const backupStates = {
  1: {
    stateId: 1,
    name: 'Tarapacá',
    cities: [
      {
        name: 'Alto Hospicio',
        id: 1107
      },
      {
        name: 'Camiña',
        id: 1402
      },
      {
        name: 'Colchane',
        id: 1403
      },
      {
        name: 'Huara',
        id: 1404
      },
      {
        name: 'Iquique',
        id: 1101
      },
      {
        name: 'Pica',
        id: 1405
      },
      {
        name: 'Pozo Almonte',
        id: 1401
      }
    ]
  },
  2: {
    stateId: 2,
    name: 'Antofagasta',
    cities: [
      {
        name: 'Antofagasta',
        id: 2101
      },
      {
        name: 'Calama',
        id: 2201
      },
      {
        name: 'María Elena',
        id: 2302
      },
      {
        name: 'Mejillones',
        id: 2102
      },
      {
        name: 'Ollague',
        id: 2202
      },
      {
        name: 'San Pedro De Atacama',
        id: 2203
      },
      {
        name: 'Sierra Gorda',
        id: 2103
      },
      {
        name: 'Taltal',
        id: 2104
      },
      {
        name: 'Tocopilla',
        id: 2301
      }
    ]
  },
  3: {
    stateId: 3,
    name: 'Atacama',
    cities: [
      {
        name: 'Alto Del Carmen',
        id: 3302
      },
      {
        name: 'Caldera',
        id: 3102
      },
      {
        name: 'Chañaral',
        id: 3201
      },
      {
        name: 'Copiapó',
        id: 3101
      },
      {
        name: 'Diego De Almagro',
        id: 3202
      },
      {
        name: 'Freirina',
        id: 3303
      },
      {
        name: 'Huasco',
        id: 3304
      },
      {
        name: 'Tierra Amarilla',
        id: 3103
      },
      {
        name: 'Vallenar',
        id: 3301
      }
    ]
  },
  4: {
    stateId: 4,
    name: 'Coquimbo',
    cities: [
      {
        name: 'Andacollo',
        id: 4103
      },
      {
        name: 'Canela',
        id: 4202
      },
      {
        name: 'Combarbalá',
        id: 4302
      },
      {
        name: 'Coquimbo',
        id: 4102
      },
      {
        name: 'Illapel',
        id: 4201
      },
      {
        name: 'La Higuera',
        id: 4104
      },
      {
        name: 'La Serena',
        id: 4101
      },
      {
        name: 'Los Vilos',
        id: 4203
      },
      {
        name: 'Monte Patria',
        id: 4303
      },
      {
        name: 'Ovalle',
        id: 4301
      },
      {
        name: 'Paiguano',
        id: 4105
      },
      {
        name: 'Punitaqui',
        id: 4304
      },
      {
        name: 'Río Hurtado',
        id: 4305
      },
      {
        name: 'Salamanca',
        id: 4204
      },
      {
        name: 'Vicuña',
        id: 4106
      }
    ]
  },
  5: {
    stateId: 5,
    name: 'Valparaíso',
    cities: [
      {
        name: 'Algarrobo',
        id: 5602
      },
      {
        name: 'Cabildo',
        id: 5402
      },
      {
        name: 'Calera',
        id: 5502
      },
      {
        name: 'Calle Larga',
        id: 5302
      },
      {
        name: 'Cartagena',
        id: 5603
      },
      {
        name: 'Casablanca',
        id: 5102
      },
      {
        name: 'Catemu',
        id: 5702
      },
      {
        name: 'Concón',
        id: 5103
      },
      {
        name: 'El Quisco',
        id: 5604
      },
      {
        name: 'El Tabo',
        id: 5605
      },
      {
        name: 'Hijuelas',
        id: 5503
      },
      {
        name: 'Isla De Pascua',
        id: 5201
      },
      {
        name: 'Juan Fernández',
        id: 5104
      },
      {
        name: 'La Cruz',
        id: 5504
      },
      {
        name: 'La Ligua',
        id: 5401
      },
      {
        name: 'Limache',
        id: 5505
      },
      {
        name: 'Llaillay',
        id: 5703
      },
      {
        name: 'Los Andes',
        id: 5301
      },
      {
        name: 'Nogales',
        id: 5506
      },
      {
        name: 'Olmué',
        id: 5507
      },
      {
        name: 'Panquehue',
        id: 5704
      },
      {
        name: 'Papudo',
        id: 5403
      },
      {
        name: 'Petorca',
        id: 5404
      },
      {
        name: 'Puchuncaví',
        id: 5105
      },
      {
        name: 'Putaendo',
        id: 5705
      },
      {
        name: 'Quillota',
        id: 5501
      },
      {
        name: 'Quilpué',
        id: 5106
      },
      {
        name: 'Quintero',
        id: 5107
      },
      {
        name: 'Rinconada',
        id: 5303
      },
      {
        name: 'San Antonio',
        id: 5601
      },
      {
        name: 'San Esteban',
        id: 5304
      },
      {
        name: 'San Felipe',
        id: 5701
      },
      {
        name: 'Santa María',
        id: 5706
      },
      {
        name: 'Santo Domingo',
        id: 5606
      },
      {
        name: 'Valparaíso',
        id: 5101
      },
      {
        name: 'Villa Alemana',
        id: 5108
      },
      {
        name: 'Viña Del Mar',
        id: 5109
      },
      {
        name: 'Zapallar',
        id: 5405
      }
    ]
  },
  6: {
    stateId: 6,
    name: "Región de O'Higgins",
    cities: [
      {
        name: 'Chépica',
        id: 6302
      },
      {
        name: 'Chimbarongo',
        id: 6303
      },
      {
        name: 'Codegua',
        id: 6102
      },
      {
        name: 'Coinco',
        id: 6103
      },
      {
        name: 'Coltauco',
        id: 6104
      },
      {
        name: 'Doñihue',
        id: 6105
      },
      {
        name: 'Graneros',
        id: 6106
      },
      {
        name: 'La Estrella',
        id: 6202
      },
      {
        name: 'Las Cabras',
        id: 6107
      },
      {
        name: 'Litueche',
        id: 6203
      },
      {
        name: 'Lolol',
        id: 6304
      },
      {
        name: 'Machali',
        id: 6108
      },
      {
        name: 'Malloa',
        id: 6109
      },
      {
        name: 'Marchihue',
        id: 6204
      },
      {
        name: 'Mostazal',
        id: 6110
      },
      {
        name: 'Nancagua',
        id: 6305
      },
      {
        name: 'Navidad',
        id: 6205
      },
      {
        name: 'Olivar',
        id: 6111
      },
      {
        name: 'Palmilla',
        id: 6306
      },
      {
        name: 'Paredones',
        id: 6206
      },
      {
        name: 'Peralillo',
        id: 6307
      },
      {
        name: 'Peumo',
        id: 6112
      },
      {
        name: 'Pichidegua',
        id: 6113
      },
      {
        name: 'Pichilemu',
        id: 6201
      },
      {
        name: 'Placilla',
        id: 6308
      },
      {
        name: 'Pumanque',
        id: 6309
      },
      {
        name: 'Quinta De Tilcoco',
        id: 6114
      },
      {
        name: 'Rancagua',
        id: 6101
      },
      {
        name: 'Rengo',
        id: 6115
      },
      {
        name: 'Requinoa',
        id: 6116
      },
      {
        name: 'San Fernando',
        id: 6301
      },
      {
        name: 'Santa Cruz',
        id: 6310
      },
      {
        name: 'San Vicente',
        id: 6117
      }
    ]
  },
  7: {
    stateId: 7,
    name: 'Maule',
    cities: [
      {
        name: 'Cauquenes',
        id: 7201
      },
      {
        name: 'Chanco',
        id: 7202
      },
      {
        name: 'Colbún',
        id: 7402
      },
      {
        name: 'Constitución',
        id: 7102
      },
      {
        name: 'Curepto',
        id: 7103
      },
      {
        name: 'Curicó',
        id: 7301
      },
      {
        name: 'Empedrado',
        id: 7104
      },
      {
        name: 'Hualañe',
        id: 7302
      },
      {
        name: 'Licantén',
        id: 7303
      },
      {
        name: 'Linares',
        id: 7401
      },
      {
        name: 'Longaví',
        id: 7403
      },
      {
        name: 'Maule',
        id: 7105
      },
      {
        name: 'Molina Maule',
        id: 7304
      },
      {
        name: 'Parral',
        id: 7404
      },
      {
        name: 'Pelarco',
        id: 7106
      },
      {
        name: 'Pelluhue',
        id: 7203
      },
      {
        name: 'Pencahue',
        id: 7107
      },
      {
        name: 'Rauco',
        id: 7305
      },
      {
        name: 'Retiro',
        id: 7405
      },
      {
        name: 'Río Claro',
        id: 7108
      },
      {
        name: 'Romeral',
        id: 7306
      },
      {
        name: 'Sagrada Familia',
        id: 7307
      },
      {
        name: 'San Clemente',
        id: 7109
      },
      {
        name: 'San Javier',
        id: 7406
      },
      {
        name: 'San Rafael',
        id: 7110
      },
      {
        name: 'Talca',
        id: 7101
      },
      {
        name: 'Teno',
        id: 7308
      },
      {
        name: 'Vichuquén',
        id: 7309
      },
      {
        name: 'Villa Alegre',
        id: 7407
      },
      {
        name: 'Yerbas Buenas',
        id: 7408
      }
    ]
  },
  8: {
    stateId: 8,
    name: 'Región del Bío Bío',
    cities: [
      {
        name: 'Alto Biobío',
        id: 8314
      },
      {
        name: 'Antuco',
        id: 8302
      },
      {
        name: 'Arauco',
        id: 8202
      },
      {
        name: 'Cabrero',
        id: 8303
      },
      {
        name: 'Cañete',
        id: 8203
      },
      {
        name: 'Chiguayante',
        id: 8103
      },
      {
        name: 'Concepción',
        id: 8101
      },
      {
        name: 'Contulmo',
        id: 8204
      },
      {
        name: 'Coronel',
        id: 8102
      },
      {
        name: 'Curanilahue',
        id: 8205
      },
      {
        name: 'Florida',
        id: 8104
      },
      {
        name: 'Hualpén',
        id: 8112
      },
      {
        name: 'Hualqui',
        id: 8105
      },
      {
        name: 'Laja',
        id: 8304
      },
      {
        name: 'Lebu',
        id: 8201
      },
      {
        name: 'Los Alamos',
        id: 8206
      },
      {
        name: 'Los Angeles',
        id: 8301
      },
      {
        name: 'Lota',
        id: 8106
      },
      {
        name: 'Mulchén',
        id: 8305
      },
      {
        name: 'Nacimiento',
        id: 8306
      },
      {
        name: 'Negrete',
        id: 8307
      },
      {
        name: 'Penco',
        id: 8107
      },
      {
        name: 'Quilaco',
        id: 8308
      },
      {
        name: 'Quilleco',
        id: 8309
      },
      {
        name: 'San Pedro de la Paz',
        id: 8108
      },
      {
        name: 'San Rosendo',
        id: 8310
      },
      {
        name: 'Santa Bárbara',
        id: 8311
      },
      {
        name: 'Santa Juana',
        id: 8109
      },
      {
        name: 'Talcahuano',
        id: 8110
      },
      {
        name: 'Tomé',
        id: 8111
      },
      {
        name: 'Tucapel',
        id: 8312
      },
      {
        name: 'Yumbel',
        id: 8313
      }
    ]
  },
  9: {
    stateId: 9,
    name: 'La Araucanía',
    cities: [
      {
        name: 'Angol',
        id: 9201
      },
      {
        name: 'Carahue',
        id: 9102
      },
      {
        name: 'Chol Chol',
        id: 9121
      },
      {
        name: 'Collipulli',
        id: 9202
      },
      {
        name: 'Cunco',
        id: 9103
      },
      {
        name: 'Curacautín',
        id: 9203
      },
      {
        name: 'Curarrehue',
        id: 9104
      },
      {
        name: 'Ercilla',
        id: 9204
      },
      {
        name: 'Freire',
        id: 9105
      },
      {
        name: 'Galvarino',
        id: 9106
      },
      {
        name: 'Gorbea',
        id: 9107
      },
      {
        name: 'Lautaro',
        id: 9108
      },
      {
        name: 'Loncoche',
        id: 9109
      },
      {
        name: 'Lonquimay',
        id: 9205
      },
      {
        name: 'Los Sauces',
        id: 9206
      },
      {
        name: 'Lumaco',
        id: 9207
      },
      {
        name: 'Melipeuco',
        id: 9110
      },
      {
        name: 'Nueva Imperial',
        id: 9111
      },
      {
        name: 'Padre Las Casas',
        id: 9112
      },
      {
        name: 'Perquenco',
        id: 9113
      },
      {
        name: 'Pitrufquén',
        id: 9114
      },
      {
        name: 'Pucón',
        id: 9115
      },
      {
        name: 'Puren',
        id: 9208
      },
      {
        name: 'Renaico',
        id: 9209
      },
      {
        name: 'Saavedra',
        id: 9116
      },
      {
        name: 'Temuco',
        id: 9101
      },
      {
        name: 'Teodoro Schmidt',
        id: 9117
      },
      {
        name: 'Toltén',
        id: 9118
      },
      {
        name: 'Traiguén',
        id: 9210
      },
      {
        name: 'Victoria',
        id: 9211
      },
      {
        name: 'Vilcún',
        id: 9119
      },
      {
        name: 'Villarrica',
        id: 9120
      }
    ]
  },
  10: {
    stateId: 10,
    name: 'Los Lagos',
    cities: [
      {
        name: 'Ancud',
        id: 10202
      },
      {
        name: 'Calbuco',
        id: 10102
      },
      {
        name: 'Castro',
        id: 10201
      },
      {
        name: 'Chaitén',
        id: 10401
      },
      {
        name: 'Chonchi',
        id: 10203
      },
      {
        name: 'Cochamó',
        id: 10103
      },
      {
        name: 'Curaco de Velez',
        id: 10204
      },
      {
        name: 'Dalcahue',
        id: 10205
      },
      {
        name: 'Fresia',
        id: 10104
      },
      {
        name: 'Frutillar',
        id: 10105
      },
      {
        name: 'Futaleufú',
        id: 10402
      },
      {
        name: 'Hualaihue',
        id: 10403
      },
      {
        name: 'Llanquihue',
        id: 10107
      },
      {
        name: 'Los Muermos',
        id: 10106
      },
      {
        name: 'Maullín',
        id: 10108
      },
      {
        name: 'Osorno',
        id: 10301
      },
      {
        name: 'Palena',
        id: 10404
      },
      {
        name: 'Puerto Montt',
        id: 10101
      },
      {
        name: 'Puerto Octay',
        id: 10302
      },
      {
        name: 'Puerto Varas',
        id: 10109
      },
      {
        name: 'Puqueldón',
        id: 10206
      },
      {
        name: 'Purranque',
        id: 10303
      },
      {
        name: 'Puyehue',
        id: 10304
      },
      {
        name: 'Queilén',
        id: 10207
      },
      {
        name: 'Quellón',
        id: 10208
      },
      {
        name: 'Quemchi',
        id: 10209
      },
      {
        name: 'Quinchao',
        id: 10210
      },
      {
        name: 'Río Negro',
        id: 10305
      },
      {
        name: 'San Juan de la Costa',
        id: 10306
      },
      {
        name: 'San Pablo',
        id: 10307
      }
    ]
  },
  11: {
    stateId: 11,
    name: 'Aysén del General Carlos Ibáñez del Campo',
    cities: [
      {
        name: 'Aysen',
        id: 11201
      },
      {
        name: 'Chile Chico',
        id: 11401
      },
      {
        name: 'Cisnes',
        id: 11202
      },
      {
        name: 'Cochrane',
        id: 11301
      },
      {
        name: 'Coyhaique',
        id: 11101
      },
      {
        name: 'Guaitecas',
        id: 11203
      },
      {
        name: 'Lago Verde',
        id: 11102
      },
      {
        name: 'OHiggins',
        id: 11302
      },
      {
        name: 'Río Ibáñez',
        id: 11402
      },
      {
        name: 'Tortel',
        id: 11303
      }
    ]
  },
  12: {
    stateId: 12,
    name: 'Magallanes y de la Antártica Chilena',
    cities: [
      {
        name: 'Antártica',
        id: 12202
      },
      {
        name: 'Cabo de Hornos',
        id: 12201
      },
      {
        name: 'Laguna Blanca',
        id: 12102
      },
      {
        name: 'Natales',
        id: 12401
      },
      {
        name: 'Porvenir',
        id: 12301
      },
      {
        name: 'Primavera',
        id: 12302
      },
      {
        name: 'Punta Arenas',
        id: 12101
      },
      {
        name: 'Río Verde',
        id: 12103
      },
      {
        name: 'San Gregorio',
        id: 12104
      },
      {
        name: 'Timaukel',
        id: 12303
      },
      {
        name: 'Torres del Paine',
        id: 12402
      }
    ]
  },
  13: {
    stateId: 13,
    name: 'Metropolitana de Santiago',
    cities: [
      {
        name: 'Alhué',
        id: 13502
      },
      {
        name: 'Buin',
        id: 13402
      },
      {
        name: 'Calera De Tango',
        id: 13403
      },
      {
        name: 'Cerrillos',
        id: 13102
      },
      {
        name: 'Cerro Navia',
        id: 13103
      },
      {
        name: 'Colina',
        id: 13301
      },
      {
        name: 'Conchalí',
        id: 13104
      },
      {
        name: 'Curacaví',
        id: 13503
      },
      {
        name: 'El Bosque',
        id: 13105
      },
      {
        name: 'El Monte',
        id: 13602
      },
      {
        name: 'Estación Central',
        id: 13106
      },
      {
        name: 'Huechuraba',
        id: 13107
      },
      {
        name: 'Independencia',
        id: 13108
      },
      {
        name: 'Isla De Maipo',
        id: 13603
      },
      {
        name: 'La Cisterna',
        id: 13109
      },
      {
        name: 'La Florida',
        id: 13110
      },
      {
        name: 'La Granja',
        id: 13111
      },
      {
        name: 'Lampa',
        id: 13302
      },
      {
        name: 'La Pintana',
        id: 13112
      },
      {
        name: 'La Reina',
        id: 13113
      },
      {
        name: 'Las Condes',
        id: 13114
      },
      {
        name: 'Lo Barnechea',
        id: 13115
      },
      {
        name: 'Lo Espejo',
        id: 13116
      },
      {
        name: 'Lo Prado',
        id: 13117
      },
      {
        name: 'Macul',
        id: 13118
      },
      {
        name: 'Maipú',
        id: 13119
      },
      {
        name: 'María Pinto',
        id: 13504
      },
      {
        name: 'Melipilla',
        id: 13501
      },
      {
        name: 'Ñuñoa',
        id: 13120
      },
      {
        name: 'Padre Hurtado',
        id: 13604
      },
      {
        name: 'Paine',
        id: 13404
      },
      {
        name: 'Pedro Aguirre Cerda',
        id: 13121
      },
      {
        name: 'Peñaflor',
        id: 13605
      },
      {
        name: 'Peñalolén',
        id: 13122
      },
      {
        name: 'Pirque',
        id: 13202
      },
      {
        name: 'Providencia',
        id: 13123
      },
      {
        name: 'Pudahuel',
        id: 13124
      },
      {
        name: 'Puente Alto',
        id: 13201
      },
      {
        name: 'Quilicura',
        id: 13125
      },
      {
        name: 'Quinta Normal',
        id: 13126
      },
      {
        name: 'Recoleta',
        id: 13127
      },
      {
        name: 'Renca',
        id: 13128
      },
      {
        name: 'San Bernardo',
        id: 13401
      },
      {
        name: 'San Joaquín',
        id: 13129
      },
      {
        name: 'San José De Maipo',
        id: 13203
      },
      {
        name: 'San Miguel',
        id: 13130
      },
      {
        name: 'San Pedro',
        id: 13505
      },
      {
        name: 'San Ramón',
        id: 13131
      },
      {
        name: 'Santiago',
        id: 13101
      },
      {
        name: 'Talagante',
        id: 13601
      },
      {
        name: 'Tiltil',
        id: 13303
      },
      {
        name: 'Vitacura',
        id: 13132
      }
    ]
  },
  14: {
    stateId: 14,
    name: 'Los Ríos',
    cities: [
      {
        name: 'Corral',
        id: 14102
      },
      {
        name: 'Futrono',
        id: 14202
      },
      {
        name: 'Lago Ranco',
        id: 14203
      },
      {
        name: 'Lanco',
        id: 14103
      },
      {
        name: 'La Unión',
        id: 14201
      },
      {
        name: 'Los Lagos',
        id: 14104
      },
      {
        name: 'Máfil',
        id: 14105
      },
      {
        name: 'Mariquina',
        id: 14106
      },
      {
        name: 'Paillaco',
        id: 14107
      },
      {
        name: 'Panguipulli',
        id: 14108
      },
      {
        name: 'Río Bueno',
        id: 14204
      },
      {
        name: 'Valdivia',
        id: 14101
      }
    ]
  },
  15: {
    stateId: 15,
    name: 'Arica y Parinacota',
    cities: [
      {
        name: 'Arica',
        id: 15101
      },
      {
        name: 'Camarones',
        id: 15102
      },
      {
        name: 'General Lagos',
        id: 15202
      },
      {
        name: 'Putre',
        id: 15201
      }
    ]
  },
  16: {
    stateId: 16,
    name: 'Región de Ñuble',
    cities: [
      {
        name: 'Bulnes',
        id: 16102
      },
      {
        name: 'Chillan',
        id: 16101
      },
      {
        name: 'Chillán Viejo',
        id: 16103
      },
      {
        name: 'Cobquecura',
        id: 16202
      },
      {
        name: 'Coelemu',
        id: 16203
      },
      {
        name: 'Coihueco',
        id: 16302
      },
      {
        name: 'El Carmen',
        id: 16104
      },
      {
        name: 'Ninhue',
        id: 16204
      },
      {
        name: 'Ñiquén',
        id: 16303
      },
      {
        name: 'Pemuco',
        id: 16105
      },
      {
        name: 'Pinto',
        id: 16106
      },
      {
        name: 'Portezuelo',
        id: 16205
      },
      {
        name: 'Quillón',
        id: 16107
      },
      {
        name: 'Quirihue',
        id: 16201
      },
      {
        name: 'Ranquil',
        id: 16206
      },
      {
        name: 'San Carlos',
        id: 16301
      },
      {
        name: 'San Fabián',
        id: 16304
      },
      {
        name: 'San Ignacio',
        id: 16108
      },
      {
        name: 'San Nicolás',
        id: 16305
      },
      {
        name: 'Trehuaco',
        id: 16207
      },
      {
        name: 'Yungay',
        id: 16109
      }
    ]
  }
}

export const shippifyDistricts = ['Alhue', 'Buin', 'Calera de Tango', 'Colina', 'Curacavi', 'El Monte', 'Isla de Maipo', 'La Pintana', 'Lampa', 'Maria Pinto', 'Melipilla', 'Padre Hurtado', 'Paine', 'Peñaflor', 'Pirque', 'San Jose de Maipo', 'San Pedro', 'Talagante', 'Tiltil']

export const backupRates = {
  1: 7390,
  2: 7190,
  3: 5590,
  4: 4990,
  5: 4390,
  6: 4490,
  7: 4690,
  8: 4990,
  9: 5190,
  10: 5390,
  11: 8290,
  12: 8490,
  13: 2990,
  14: 5290,
  15: 7590,
  16: 4890
}
