import axios from 'axios'
import { useContext } from 'react'
import { API_BASE_URL_DEV } from '../constants/url'
import { backupRates, backupStates } from '../constants/states'
import { DataContext } from '../context/DataContext'
import { normalizeRegionName, regionIdApi } from '../helpers/addressParser'
import apiInstance from '../helpers/apiInstance'
import { splitPointsByType } from '../helpers/filterPoints'
import { useShopify } from './useShopify'
import { companyInfoAvailable, isLocalStorageAvailable, setCompanyLocalStorage } from '../helpers/localStorage'
import { decryptToken } from '../helpers/utils'
import { filterServices, joinShippingRates } from '../helpers/filterServices'
import { STORES_DEFAULT_WEIGHT } from '../constants/stores'
import { errorMessage } from '../helpers/bot'

export const useApiPinflag = () => {
  const {
    cartData,
    setPointsData,
    shopifyLocations,
    setCompanyInfo,
    companyInfo,
    setLoaderInfo
  } = useContext(DataContext)
  const { iframeLoadedShopify, availableShopifyLocations, calculateShippingRates } = useShopify()

  const getCompanyInfo = () => {
    const searchParams = new URLSearchParams(document.location.search)
    const pinflagApiKey =
      searchParams.get('token') || process.env.REACT_APP_API_KEY
    // getFinalLoaderInfo(pinflagApiKey)
    if (isLocalStorageAvailable()) {
      const companyLocalStorage = companyInfoAvailable(pinflagApiKey)
      if (companyLocalStorage && companyLocalStorage.mapboxToken) {
        const hideCheckout = companyLocalStorage.checkout === false
        iframeLoadedShopify(!hideCheckout)
        return setCompanyInfo((prevState) => {
          return {
            ...prevState,
            ...companyLocalStorage
          }
        })
      }
    }
    if (pinflagApiKey) {
      apiInstance(pinflagApiKey)
        .get('/company/personalization', {
          cache: {
            maxAge: 5 * 60 * 1000, // override default cache duration for this request
            exclude: { query: false } // don't include query parameters in cache key
          },
          headers: { 'x-api-key': pinflagApiKey }
        })
        .then((res) => {
          iframeLoadedShopify(res.data.checkout)
          let labelPricePickup = res.data.storePickupPriceLabel
          if (!isNaN(parseInt(res.data.storePickupPriceLabel))) labelPricePickup = '$' + res.data.storePickupPriceLabel
          const companyObject = {
            logoUrl: res.data.logoUrl,
            primaryColor: res.data.primaryColor || '#394860',
            secondaryColor: res.data.secondaryColor || '#00B7F9',
            companyName: res.data.company.nombreempresa,
            userId: res.data.company.userId,
            pinflagApiKey,
            ccDiscountPercentageLabel: res.data.ccDiscountPercentageLabel,
            storePickupPriceLabel: labelPricePickup,
            storePickupTypeLabel: res.data.storePickupTypeLabel,
            checkout: res.data.checkout,
            useBillingData: res.data.useBillingData,
            mapboxToken: decryptToken(res.data.mapboxToken, process.env.REACT_APP_TOKEN_ENCRYPTATION_KEY)
          }
          isLocalStorageAvailable() && setCompanyLocalStorage(companyObject)
          return setCompanyInfo((prevState) => {
            return {
              ...prevState,
              ...companyObject
            }
          })
        })
        .catch((err) => {
          setCompanyInfo((prevState) => {
            return {
              ...prevState,
              pinflagApiKey
            }
          })
          console.error(err)
        })
    }
  }

  const getFinalLoaderInfo = (pinflagApiKey) => {
    axios
      .get(`${API_BASE_URL_DEV}/companies/1/personalization/did-you-know`, {
        headers: {
          'x-api-key': '65d2b814-51e7-473a-b8fd-4d88a991cb62'
        }
      })
      .then((res) => {
        setLoaderInfo(res.data)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const serviceClickAndCollect = async (point) => {
    const { district, stateId, address } = point
    const { pinflagApiKey } = companyInfo
    const { subtotal, totalWeight } = cartData
    const body = {
      address,
      cityName: district,
      stateId,
      price: parseInt(subtotal, 10),
      weight: totalWeight
    }
    try {
      const response = await apiInstance(pinflagApiKey)
        .post('/delivery-cost', body, { headers: { 'x-api-key': pinflagApiKey } })
      return response.data.services[0]
    } catch (err) {
      body.source = 'CNC'
      errorMessage(err, companyInfo, '/delivery-cost', body)
      return { name: 'Despacho normal' }
    }
  }

  const calculateShippingPrice = async (district, region, setData, setIsLoading, handleConfirmation, source) => {
    const { subtotal, totalWeight } = cartData
    const { companyName, pinflagApiKey } = companyInfo
    const stateId = parseInt(regionIdApi[normalizeRegionName(region)], 10)
    const body = {
      cityName: district,
      stateId,
      price: parseInt(subtotal, 10),
      weight: STORES_DEFAULT_WEIGHT[companyName] || totalWeight || undefined
    }
    try {
      const [thirdPartyRates, pinflagServices] = await Promise.all([
        calculateShippingRates(district, region, companyName),
        apiInstance(pinflagApiKey)
          .post('/delivery-cost', body)
      ])
      let servicesData = pinflagServices.data
      if (thirdPartyRates) servicesData = joinShippingRates(thirdPartyRates, servicesData)
      if (servicesData.services[0].price === null) throw new Error('Price null')
      servicesData = filterServices(servicesData, shopifyLocations, { companyName })
      if (servicesData.services.length === 1) handleConfirmation(servicesData.services[0])
      setData(servicesData)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      body.source = source
      errorMessage(error, companyInfo, '/delivery-cost', body)
      const backupRate = stateId ? backupRates[stateId] : 3190
      const data = {
        services: [
          {
            packageDeliveryTime: '2-5 días hábiles',
            price: backupRate,
            serviceType: 'standard',
            courier: 'blueexpress'
          }
        ]
      }
      setData(data)
      handleConfirmation(data.services[0])
      setIsLoading(false)
    }
  }

  const getRegions = (setData, setIsLoading) => {
    const { pinflagApiKey } = companyInfo
    apiInstance()
      .get('/states-cities', {
        headers: {
          'x-api-key': pinflagApiKey
        }
      })
      .then((res) => {
        setData(res.data)
        setIsLoading(false)
      })
      .catch((err) => {
        console.log(err, 'error')
        setData(backupStates)
        setIsLoading(false)
      })
  }

  const getPoints = (setIsLoading) => {
    const { subtotal, totalWeight } = cartData
    const price = Number(subtotal)
    const searchParams = new URLSearchParams(document.location.search)
    const pinflagApiKey =
      searchParams.get('token') || process.env.REACT_APP_API_KEY

    apiInstance()
      .get(`/map?${totalWeight ? 'weight=' + totalWeight + '&' : ''}price=${price || 10000}`, {
        headers: { 'x-api-key': pinflagApiKey }
      })
      .then(async (res) => {
        await availableShopifyLocations()
        // const points = filterPoints(res.data.features, shopifyLocations)
        const points = res.data.features
        const splitPoints = splitPointsByType(points)
        setPointsData((prevState) => {
          return {
            ...prevState,
            localPoints: splitPoints[0],
            pinflagPoints: splitPoints[1]
          }
        })
        setIsLoading && setIsLoading(false)
      })
      .catch((err) => {
        errorMessage(err, companyInfo, `/map?${totalWeight ? 'weight=' + totalWeight + '&' : ''}price=${price || 10000}`)
        setIsLoading && setIsLoading(false)
        console.log(err)
      })
  }

  return {
    getCompanyInfo,
    calculateShippingPrice,
    getRegions,
    getFinalLoaderInfo,
    getPoints,
    serviceClickAndCollect
  }
}
