import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'

export const DataContext = createContext()

export const DataProvider = ({ children }) => {
  const [steps, setSteps] = useState([
    {
      label: 'Identificación',
      stepId: 1,
      completed: false,
      disabled: false,
      subSteps: []
    },
    {
      label: 'Entrega',
      stepId: 2,
      completed: false,
      disabled: true,
      subSteps: [
        {
          label: 'Method',
          subStepId: 2.1,
          completed: false
        },
        {
          label: 'Address',
          subStepId: 2.2,
          completed: false
        },
        {
          label: 'Confirmation',
          subStepId: 2.3,
          completed: false
        }
      ]
    }
  ])

  const [selectedShippingMethod, setSelectedShippingMethod] = useState(null)

  const [selectedServiceType, setSelectedServiceType] = useState({})

  const [deliveryInfo, setDeliveryInfo] = useState({})

  const [currentStep, setCurrentStep] = useState(1)

  const [userInfo, setUserInfo] = useState({
    email: '',
    names: '',
    phone: '',
    rut: '',
    saveData: false
  })

  const [addressInfo, setAddressInfo] = useState({
    userAddress: '',
    aditionalInfo: '',
    region: '',
    district: '',
    price: '',
    deliveryType: '',
    courier: '',
    serviceType: '',
    serviceName: ''
  })

  const [billingInfo, setBillingInfo] = useState({
    corporateName: '',
    category: '',
    corporateRut: '',
    corporatePhone: '',
    address: '',
    district: ''
  })

  const [isPaying, setIsPaying] = useState(false)

  const [ecommerceInfo, setEcommerceInfo] = useState({
    cms: '',
    vtexOrderFormId: '',
    checkoutId: '',
    shopifyCurrentCheckout: {},
    currentUrl: '',
    loggedIn: false
  })

  const [companyInfo, setCompanyInfo] = useState({
    logoUrl: '',
    primaryColor: '#394860',
    secondaryColor: '#00B7F9',
    companyName: '',
    userId: ''
  })

  const [cartData, setCartData] = useState({
    lines: [],
    subtotal: 0,
    total: 0,
    shippingCost: null,
    itemsCount: 0,
    totalWeight: 0
  })

  const [isPickup, setIsPickup] = useState(false)

  const [pointsData, setPointsData] = useState({
    localPoints: [],
    pinflagPoints: [],
    localPointSelected: {},
    pinflagPointSelected: {}
  })

  const [cartNotes, setCartNotes] = useState('')

  const [shopifyLocations, setShopifyLocations] = useState({
    disabled: false,
    locations: []
  })

  const [loaderInfo, setLoaderInfo] = useState([
    {
      imageUrl: 'https://did-you-know-image.s3.sa-east-1.amazonaws.com/woman-loader.png',
      backgroundColor: '#FFFFFF',
      body: 'La generación X compra más online que los Baby Boomers y Millennials',
      bodyColor: '#000000',
      title: '¿Sabías que...?'
    }
  ])
  return (
    <DataContext.Provider
      value={{
        steps,
        setSteps,
        currentStep,
        setCurrentStep,
        userInfo,
        setUserInfo,
        ecommerceInfo,
        setEcommerceInfo,
        isPaying,
        setIsPaying,
        cartData,
        setCartData,
        addressInfo,
        setAddressInfo,
        selectedShippingMethod,
        setSelectedShippingMethod,
        isPickup,
        setIsPickup,
        pointsData,
        setPointsData,
        cartNotes,
        setCartNotes,
        billingInfo,
        setBillingInfo,
        shopifyLocations,
        setShopifyLocations,
        companyInfo,
        setCompanyInfo,
        loaderInfo,
        setLoaderInfo,
        selectedServiceType,
        setSelectedServiceType,
        deliveryInfo,
        setDeliveryInfo
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

DataProvider.propTypes = {
  children: PropTypes.node.isRequired
}
