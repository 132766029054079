import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Input,
  Select,
  Spinner,
  Icon,
  SectionTitle,
  ModalServiceType
} from '@pinflag/pinflag-ui-kit'
import { DataContext } from '../../context/DataContext'
import ReturnPickupPoint from '../ReturnPickupPoint'
import { useForm } from 'react-hook-form'
import { InputValidator } from '../../helpers/Validator'
import {
  getRegionById,
  regionIdApi,
  normalizeRegionName
} from '../../helpers/addressParser'
import { setServiceAndShippingcost, onlySomeLetters } from '../../helpers/utils'
import { useApiPinflag } from '../../hook/useApiPinflag'
import { useCmsAdapter } from '../../hook/useCmsAdapter'

export const ManualDirection = ({ setter, handleAddressInformation }) => {
  const {
    addressInfo,
    setSelectedShippingMethod,
    companyInfo,
    setAddressInfo,
    selectedServiceType,
    setSelectedServiceType,
    deliveryInfo,
    setDeliveryInfo,
    setCartData,
    cartData
  } = useContext(DataContext)
  const { primaryColor, secondaryColor } = companyInfo
  const { postTrackingEvent } = useCmsAdapter()
  const { getRegions } = useApiPinflag()
  const [regions, setRegions] = useState({})
  const [loading, setLoading] = useState(true)
  const [openServiceTypeModal, setOpenServiceTypeModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [customErrors, setCustomErrors] = useState({})
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: {
      street: addressInfo.userAddress,
      streetNumber: '',
      regionId: regionIdApi[normalizeRegionName(addressInfo.region)],
      district: addressInfo.district,
      aditionalInfo: addressInfo.aditionalInfo
    },
    mode: 'all'
  })

  const { calculateShippingPrice } = useApiPinflag()

  const addressForm = {
    street: register('street', {
      required: 'Campo requerido',
      validate: value =>
        InputValidator.isStringValid(value) || 'Ingresa una calle válida',
      onChange: e => {
        setValue('street', onlySomeLetters(e.target.value, 45))
      }
    }),
    streetNumber: register('streetNumber', {
      required: 'Campo requerido',
      validate: value =>
        InputValidator.isStreetNumberValid(value) || 'Ingresa un número válido',
      onChange: e => {
        setValue('streetNumber', onlySomeLetters(e.target.value, 10))
      }
    }),
    regionId: register('regionId', { required: 'Campo requerido' }),
    district: register('district', { required: 'Campo requerido' }),
    aditionalInfo: register('aditionalInfo', {
      validate: value =>
        InputValidator.isAditionalInfoValid(value) || 'Máximo 55 caracteres',
      onBlur: () => {
        setCustomErrors({ ...customErrors, aditionalInfo: errors?.aditionalInfo })
      },
      onChange: e => setValue('aditionalInfo', onlySomeLetters(e.target.value, 55))
    })
  }

  const handleGoConfirmation = (selectedService) => {
    postTrackingEvent('click - submit', {
      currentCheckoutSubstep: '2 - manual-home-delivery',
      previousCheckoutStep: '2',
      previousCheckoutSubstep: '2'
    })
    setServiceAndShippingcost(selectedService, setSelectedServiceType, setCartData, cartData)
    handleAddressInformation({
      serviceType: selectedService.serviceType,
      price: selectedService.price,
      courier: deliveryInfo.courier ? deliveryInfo.courier : selectedService.courier,
      serviceName: selectedService.name
    })
    setOpenServiceTypeModal(false)
  }

  const handleOpenServiceTypeModal = (addressData) => {
    setIsLoading(true)
    const { street, streetNumber, district, regionId, aditionalInfo } = addressData
    calculateShippingPrice(district, getRegionById(regionId), setDeliveryInfo, setIsLoading, handleGoConfirmation, 'address manual')
    setAddressInfo({
      userAddress: `${street} ${streetNumber}`,
      district,
      region: getRegionById(regionId),
      aditionalInfo,
      deliveryType: 'Despacho a domicilio'
    })
  }

  const handleRegionChange = data => {
    setValue('regionId', data.value)
  }

  const handleDistrictChange = data => {
    setValue('district', data.value)
  }

  const handleSelectServiceType = (service) => {
    setSelectedServiceType(service)
  }

  const regionValidator = () => { return regions }

  useEffect(() => {
    getRegions(setRegions, setLoading)
    postTrackingEvent('impression', {
      currentCheckoutSubstep: '2 - manual-home-delivery',
      previousCheckoutStep: '2',
      previousCheckoutSubstep: '1'
    })
  }, [])

  useEffect(() => {
    if (Object.keys(deliveryInfo).length) {
      setServiceAndShippingcost(deliveryInfo.services[0], setSelectedServiceType, setCartData, cartData)
      if (deliveryInfo.services.length > 1) {
        setOpenServiceTypeModal(!openServiceTypeModal)
      }
    }
  }, [deliveryInfo])

  const handleCloseModal = () => {
    setSelectedServiceType({})
    setCartData(prevState => {
      return {
        ...prevState,
        shippingCost: null,
        total: cartData.subtotal || 0
      }
    })
    setOpenServiceTypeModal(false)
  }

  if (loading) {
    return (
      <div className='full_width_spinner md:w-[537px]'>
        <Spinner width={44} />
      </div>
    )
  }
  return (
    <>
      <ModalServiceType
        services={deliveryInfo.services ? deliveryInfo.services : []}
        serviceSelected={selectedServiceType.serviceType ? selectedServiceType.serviceType : ''}
        handleSelectServiceType={handleSelectServiceType}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        handlePayment={() => handleGoConfirmation(selectedServiceType)}
        handleCloseModal={handleCloseModal}
        isOpen={openServiceTypeModal}
        className='bg-white'
      />
      <form className='md:w-[537px] h-full' onSubmit={handleSubmit(handleOpenServiceTypeModal)}>
        <div className='bg-slate-100 h-full flex flex-col pb-4'>
          <div className='rounded mt-2 md:mt-5 mx-[22px] bg-white text-xs'>
            <div className='flex md:text-sm text-xs bg-white rounded'>
              <ReturnPickupPoint setter={setSelectedShippingMethod} />
            </div>
          </div>
          <div className='py-3 md:mb-2'>
            <div className='flex flex-col bg-white rounded-lg mx-[21px] px-2 justify-around py-2'>
              <SectionTitle title='Dirección' iconName='home' className='mb-3 ml-2' primaryColor={primaryColor} />
              <div className='mx-2 space-y-2'>
                {isLoading
                  ? (
                    <div className='flex flex-col gap-y-4 justify-center items-center mb-10'>
                      <Spinner />
                      <div className='text-gray-500 text-sm'>Obteniendo las tarifas de envío disponible...</div>
                    </div>
                    )
                  : (
                    <div className='space-y-2'>
                      <div className='md:flex'>
                        <div className='md:w-1/2 md:pr-2 pb-4 md:pb-0'>
                          <Select
                            containerClass='bg-slate-100'
                            label='Región'
                            options={Object.keys(regionValidator()).map(regionId => ({
                              label: regionValidator()[regionId].name,
                              value: regionId
                            }))}
                            onChange={handleRegionChange}
                            errors={errors?.regionId}
                            defaultValue={{
                              label: watch('regionId')
                                ? regions[watch('regionId')].name
                                : '',
                              value: watch('regionId')
                            }}
                            testId="region-select"
                          />
                        </div>
                        <div className='md:w-1/2 md:pl-1'>
                          <Select
                            containerClass='bg-slate-100'
                            label='Comuna'
                            options={
                              !watch('regionId')
                                ? []
                                : regionValidator()[watch('regionId')].cities.map(district => ({
                                  label: district.name,
                                  value: district.name
                                }))
                            }
                            onChange={handleDistrictChange}
                            errors={errors?.district}
                            defaultValue={{
                              label: watch('district'),
                              value: watch('district')
                            }}
                            testId="district-select"
                          />
                        </div>
                      </div>
                      <div className='flex'>
                        <div className='w-3/4 md:w-1/2 md:pr-2 pr-2'>
                          <Input
                            containerClass='w-full md:w-full mr-2'
                            label='Calle'
                            name='street'
                            innerRef={addressForm.street.ref}
                            type='text'
                            onChange={addressForm.street.onChange}
                            onBlur={addressForm.street.onBlur}
                            errors={errors?.street}
                            customErrors={errors?.street}
                            secondaryColor={secondaryColor}
                            testId="street-info"
                          />
                        </div>
                        <div className='w-2/4 md:w-1/2 md:pl-1'>
                          <Input
                            containerClass='w-full md:w-full'
                            label='Número'
                            name='streetNumber'
                            innerRef={addressForm.streetNumber.ref}
                            type='text'
                            onChange={addressForm.streetNumber.onChange}
                            onBlur={addressForm.streetNumber.onBlur}
                            errors={errors?.streetNumber}
                            customErrors={errors?.streetNumber}
                            secondaryColor={secondaryColor}
                            testId="number-info"
                          />
                        </div>
                      </div>
                      <div className='md:w-full'>
                        <div className='md:flex md:justify-end md:pl-1'>
                          <Input
                            containerClass='md:w-1/2'
                            label='Depto/Oficina/Block'
                            name='aditionalInfo'
                            innerRef={addressForm.aditionalInfo.ref}
                            type='text'
                            onChange={addressForm.aditionalInfo.onChange}
                            onBlur={addressForm.aditionalInfo.onBlur}
                            errors={errors.aditionalInfo}
                            customErrors={customErrors.aditionalInfo}
                            secondaryColor={secondaryColor}
                            testId="additional-info"
                          />
                        </div>
                        <div className='flex justify-end'>
                          <Button
                            label='Modo Autocompletar'
                            LeftIcon={<Icon name='edit' className='mr-1 text-sm' width={9} />}
                            onClick={() => {
                              setter(false)
                            }}
                            className='text-[11px] bg-slate-100 px-4 py-1 mt-3 rounded-2xl font-light flex items-center h-[23px] w-auto'
                            testId='go-autofill'
                          />
                        </div>
                        <div className='hidden md:flex justify-end'>
                          <div className='pt-5 text-center py-1'>
                            <Button
                              role='submit'
                              className='bg-black text-white w-[149px] rounded-3xl disabled:bg-slate-100 disabled:text-gray-500 h-9 text-base'
                              label='Continuar'
                              disabled={Object.keys(watch()).some(
                                key => key !== 'aditionalInfo' && watch(key) === ''
                              )}
                              customStyle={{ backgroundColor: secondaryColor }}
                              testId='go-confirmation'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    )}
              </div>
            </div>
          </div>
        </div>

        <div className='md:hidden flex flex-col relative justify-between bg-white'>
          <div className='w-full text-center my-4'>
            <Button
              role='submit'
              className='h-[3rem] bg-black text-white text-base w-4/5 rounded-3xl disabled:bg-slate-100 disabled:text-gray-500'
              label='Continuar'
              disabled={Object.keys(watch()).some(
                key => key !== 'aditionalInfo' && watch(key) === ''
              )}
              customStyle={{ backgroundColor: secondaryColor }}
              testId='go-confirmation'
            />
          </div>
        </div>
      </form>
    </>
  )
}

ManualDirection.propTypes = {
  setter: PropTypes.func,
  handleAddressInformation: PropTypes.func
}
