import { useContext } from 'react'
import { DataContext } from '../context/DataContext'
import { normalizeRegionName } from '../helpers/addressParser'
import { transformCart, transformCartTotals } from '../helpers/utils'

export const useMagento = () => {
  const {
    userInfo,
    addressInfo,
    setIsPaying,
    setEcommerceInfo,
    setCartData,
    ecommerceInfo
  } = useContext(DataContext)

  const setInitialInformationMagento = message => {
    const { cms, items, totals, currentUrl } = message
    if (cms !== undefined && cms !== 'magento') return
    setEcommerceInfo(prevState => {
      return { ...prevState, cms, currentUrl }
    })

    setCartData(prevState => {
      return {
        ...prevState,
        lines: transformCart(items),
        ...transformCartTotals(totals)
      }
    })
  }

  const iframeLoadedMagento = () => {
    window.parent.postMessage(
      {
        type: 'iframe-mounted',
        origin: 'pinflag-checkout-iframe',
        message: true
      },
      '*'
    )
  }

  const removeIframeMagento = () => {
    window.parent.postMessage(
      {
        type: 'remove-iframe',
        origin: 'pinflag-checkout-iframe',
        message: true
      },
      '*'
    )
  }

  const redirectToHomeMagento = () => {
    window.parent.postMessage(
      {
        type: 'redirect-to-home',
        origin: 'pinflag-checkout-iframe',
        message: true
      },
      '*'
    )
  }

  const submitFormMagento = () => {
    setTimeout(() => {
      window.parent.postMessage(
        {
          type: 'submit-form',
          origin: 'pinflag-checkout-iframe',
          message: true
        },
        '*'
      )
    }, 3000)

    setTimeout(() => setIsPaying(false), 8000)
  }

  const shippingPostMessageMagento = () => {
    if (ecommerceInfo.cms !== undefined && ecommerceInfo.cms !== 'magento') { return }
    const firstname = userInfo.names.substring(0, userInfo.names.indexOf(' '))
    const lastname = userInfo.names.substring(userInfo.names.indexOf(' ') + 1)
    window.parent.postMessage(
      {
        type: 'set-form-data',
        origin: 'pinflag-checkout-iframe',
        message: {
          'custom_attributes[pinflag_address_comment]':
            addressInfo.aditionalInfo,
          'custom_attributes[pinflag_shipping_method]':
            addressInfo.deliveryType,
          'custom_attributes[pinflag_shipping_price]': `${addressInfo.price}`,
          'custom_attributes[pinflag_save_customer_info]': userInfo.saveData,
          'custom_attributes[rut]': userInfo.rut,
          'street[0]': addressInfo.userAddress,
          city_id: addressInfo.district,
          firstname,
          lastname,
          region_id: normalizeRegionName(addressInfo.region),
          telephone: userInfo.phone,
          username: userInfo.email
        }
      },
      '*'
    )
  }

  return {
    setInitialInformationMagento,
    iframeLoadedMagento,
    removeIframeMagento,
    shippingPostMessageMagento,
    submitFormMagento,
    redirectToHomeMagento
  }
}
