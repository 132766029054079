export const STORES_DISCOUNT_CYC = { SELFIE: 30 }

export const STORES_REDIRECT_SHIPPING_STEP = []

export const LOCAL_PICKUP_DELIVERY_TIME = {}

export const STORES_STOCKABLES = ['PinflagTestStore', 'Lippi', 'Haka Honu', 'Due Scarpe']

export const STORES_DEFAULT_WEIGHT = {
  'Safety Store B2B': 20
}

export const STORES_WITH_THIRD_PARTY_RATES = ['Atakama Outdoor']

export const BILLING_TYPE_STORES = {
  'Atakama Outdoor': 'attributes[billing_documento]=33&',
  Ironside: 'attributes[tipo_documento]=FACTURA&'
}
