import React, { useContext, useState } from 'react'
import { DataContext } from '../../context/DataContext'
import { AddressConfirmation } from '../AddressConfirmation'
import AddressUserData from './AddressUserData'
import { ManualDirection } from './ManualDirection'

const AddressTypeInputSelector = () => {
  const [isEditing, setIsEditing] = useState(false)
  const [selectedPointData, setSelectedPointData] = useState(undefined)
  const { steps, setAddressInfo, setSteps } = useContext(DataContext)

  const handleAddressInformation = ({
    serviceType,
    price,
    courier,
    serviceName
  }) => {
    setAddressInfo(prevState => {
      return {
        ...prevState,
        price,
        courier,
        serviceType,
        serviceName
      }
    })
    const updatedSteps = [...steps]
    updatedSteps[0].completed = true
    updatedSteps[1].disabled = false
    updatedSteps[1].subSteps[0].completed = true
    updatedSteps[1].subSteps[1].completed = true
    updatedSteps[1].subSteps[2].completed = true
    setSteps(updatedSteps)
  }

  return (
    <div className='h-full'>
      {steps[1].subSteps[1].completed
        ? <AddressConfirmation selectedPointData={selectedPointData} handleAddressInformation={handleAddressInformation}/>
        : <>
          {!isEditing
            ? (
            <AddressUserData setter={setIsEditing} setSelectedPointData={setSelectedPointData} handleAddressInformation={handleAddressInformation}/>
              )
            : (
            <ManualDirection setter={setIsEditing} handleAddressInformation={handleAddressInformation}/>
              )}
        </>
      }
    </div>
  )
}

export default AddressTypeInputSelector
