export function gaOpenedPinmapPostMessage () {
  window.parent.postMessage(
    {
      type: 'ga-opened-pinmap',
      origin: 'pinflag-shopify-pinmap-pro',
      message: 'opened_pinmap'
    },
    '*'
  )
}

export function gaCompletedUserInfoPostMessage () {
  window.parent.postMessage(
    {
      type: 'ga-event-completed-user-info',
      origin: 'pinflag-shopify-pinmap-pro',
      message: 'pinmap_user_info_filled'
    },
    '*'
  )
}

export function gaPaymentPostMessage () {
  window.parent.postMessage(
    {
      type: 'ga-event-go-payment',
      origin: 'pinflag-shopify-pinmap-pro',
      message: 'pinmap_go_to_pay'
    },
    '*'
  )
}
