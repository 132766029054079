
export const filterServices = (data, shopifyLocations, conditions) => {
  const { companyName } = conditions
  const shipFromStore = data.services.filter(service => service.serviceType === 'ship-from-store')[0]
  if (shipFromStore) {
    const orderedServices = data.services.filter(service => service.serviceType !== 'ship-from-store')
    if (shopifyLocations.locations.includes(shipFromStore.warehouseCmsId) || companyName === 'Ironside') {
      orderedServices.unshift(shipFromStore)
    }
    data.services = orderedServices
  }
  return data
}

export const joinShippingRates = (thirdPartyRates, pinflagServices) => {
  thirdPartyRates.forEach(rate => {
    if (pinflagServices.services.every(service => service.name !== rate.title)) {
      pinflagServices.services.push({
        courier: '',
        packageDeliveryTime: '',
        serviceType: rate.handle,
        price: Number(rate.price.amount),
        name: rate.title,
        description: ''
      })
    }
  })
  return pinflagServices
}
