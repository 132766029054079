export const InputValidator = {
  isEmailValid: email => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  },

  isStringValid: str => {
    if (str === null) return true
    if (str && str.length === 0) return false
    return str?.length > 2
  },
  isRutValid: str => {
    if (str === null) return true
    if (str && str.length === 0) return false
    if (str && str.length > 12) return false
    if (str && str === '00.000.000-0') return false
    if (str && str === '11.111.111-1') return false
    if (str && str === '22.222.222-2') return false
    if (str && str === '33.333.333-3') return false
    if (str && str === '44.444.444-4') return false
    if (str && str === '55.555.555-5') return false
    if (str && str === '66.666.666-6') return false
    if (str && str === '77.777.777-7') return false
    if (str && str === '88.888.888-8') return false
    if (str && str === '99.999.999-9') return false

    const checkRut = (rut) => {
      const valor = rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase()

      const cuerpo = valor.slice(0, -1)
      let dv = valor.slice(-1).toUpperCase()

      if (cuerpo.length < 7) {
        return false
      }

      let suma = 0
      let multiplo = 2

      for (let i = 1; i <= cuerpo.length; i++) {
        const index = multiplo * valor.charAt(cuerpo.length - i)

        suma = suma + index

        if (multiplo < 7) {
          multiplo = multiplo + 1
        } else {
          multiplo = 2
        }
      }

      const dvEsperado = 11 - (suma % 11)
      /* eslint-disable */
      dv = dv == 'K' ? 10 : dv
      dv = dv == 0 ? 11 : dv

      if (dvEsperado != dv) {
        return false
      }
      /* eslint-enable */
      return true
    }
    return checkRut(str)
  },
  isPhoneValid: (str, cms) => {
    if (str === null) return true
    if (str && str.length === 0) return false
    // Check if the phone number has exactly 9 digits
    if (str.length !== 9) {
      return false
    }
    if (cms === 'shopify') {
      // Check if the first AND second digits are '2'
      if (str[0] === '2' && str[1] === '2') {
        return true
      }
      // Check if the first digit is not 0, 1, 2, 3, 4, 6, or 7
      if (['0', '1', '2', '3', '4', '6', '7'].includes(str[0])) {
        return false
      }
      // Check if the first two digits are '56'
      if ((str[0] === '5' && str[1] === '6')) {
        return false
      }
      // Check if the second digit is not 0 or 1
      if (str[1] === '0' || str[1] === '1') {
        return false
      }
    }
    return true
  },
  isStreetNumberValid: str => {
    if (str === null) return true
    if (str && str.length === 0) return false
    return str.length > 0 && str.length < 6 && !isNaN(str)
  },
  isNameValid: str => {
    if (str === null) return true
    if (str && str.length === 0) return false
    return str.trim().split(' ').length > 1 && str.trim().split(' ')[1] !== ''
  },
  isAditionalInfoValid: srt => {
    return srt.length <= 55
  }
}
