export const DELIVERY_TYPE_TRANSLATION = {
  'Despacho a domicilio': 'Envío a domicilio',
  'Retiro en tienda': 'Retiro en Oficina',
  'Retiro en Tienda': 'Retiro en Tienda',
  'Retiro en punto': 'Pinflag Click and Collect'
}

export const BILLING_KEY_TRANSLATION = {
  corporateName: 'Razón social',
  category: 'Giro',
  corporateRut: 'Rut',
  corporatePhone: 'Teléfono',
  address: 'Dirección',
  district: 'Comuna',
  regionCode: 'Región'
}

export const BILLING_KEY_TRANSLATION_STORES = {
  'Atakama Outdoor': {
    corporateName: 'billing_rzn_social',
    category: 'billing_giro',
    corporateRut: 'billing_rut',
    corporatePhone: 'billing_phone',
    address: 'billing_direccion',
    district: 'billing_comuna',
    regionCode: 'billing_region'
  },
  Ironside: {
    corporateName: 'razon_social',
    category: 'giro',
    corporateRut: 'rut',
    corporatePhone: 'phone',
    address: 'direccion_tributaria',
    district: 'comuna',
    regionCode: 'region'
  }
}
