import { useContext } from 'react'
import { DataContext } from '../context/DataContext'
import { normalizeRegionName, regionCode } from '../helpers/addressParser'

export const useWoocommerce = () => {
  const {
    userInfo,
    addressInfo,
    setEcommerceInfo,
    ecommerceInfo,
    setCartData
  } = useContext(DataContext)

  const setInitialInformationWoocommerce = message => {
    const { cms, currentUrl, cartItems } = message
    if (cms !== 'woocommerce') return
    setEcommerceInfo(prevState => {
      return { ...prevState, cms, currentUrl, cartItems }
    })
    const totalPrice = cartItems.reduce(
      (sum, { lineTotal }) => sum + Number(lineTotal),
      0
    )
    const totalCount = cartItems.reduce(
      (sum, { quantity }) => sum + quantity,
      0
    )
    setCartData(prevState => {
      return {
        ...prevState,
        total: Math.trunc(totalPrice),
        subtotal: Math.trunc(totalPrice),
        lines: cartItems,
        itemsCount: totalCount
      }
    })
  }

  const iframeLoadedWoocommerce = () => {
    window.parent.postMessage(
      {
        type: 'iframe-mounted',
        origin: 'pinflag-checkout-iframe',
        message: true
      },
      '*'
    )
  }

  const redirectToHomeWoocommerce = () => {
    window.parent.postMessage(
      {
        type: 'redirect-to-home',
        origin: 'pinflag-checkout-iframe',
        message: true
      },
      '*'
    )
  }

  const shippingPostMessageWoocommerce = () => {
    if (ecommerceInfo.cms !== 'woocommerce') return
    const firstname = userInfo.names.substring(0, userInfo.names.indexOf(' '))
    const lastname = userInfo.names.substring(userInfo.names.indexOf(' ') + 1)
    window.parent.postMessage(
      {
        type: 'set-form-data',
        origin: 'pinflag-checkout-iframe',
        message: {
          firstname,
          lastname,
          address: addressInfo.userAddress,
          aditionalInfo: `${addressInfo.aditionalInfo}|${userInfo.rut}`,
          phone: userInfo.phone,
          email: userInfo.email,
          city: addressInfo.district,
          regionCode: `CL-${regionCode[normalizeRegionName(addressInfo.region)]}`,
          postcode: addressInfo.price
        }
      },
      '*'
    )
  }

  return {
    setInitialInformationWoocommerce,
    iframeLoadedWoocommerce,
    shippingPostMessageWoocommerce,
    redirectToHomeWoocommerce
  }
}
