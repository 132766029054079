import React, { useEffect, useContext, useState } from 'react'
import './Tailwind.css'
import { DesktopStepper, Stepper } from '@pinflag/pinflag-ui-kit'
import { DataContext } from './context/DataContext'
import { Header } from './components/Header'
import FinalLoader from './components/FinalLoader'
import { useApiPinflag } from './hook/useApiPinflag'
import { useMagento } from './hook/useMagento'
import { useVtex } from './hook/useVtex'
import ShippingOptionRenderer from './components/organism/ShippingOptionRenderer'
import { useShopify } from './hook/useShopify'
import UserForm from './components/UserForm'
import { useCmsAdapter } from './hook/useCmsAdapter'
import { useWoocommerce } from './hook/useWoocommerce'
import { getUserInfoLocalStorage } from './helpers/localStorage'
import { abreviatedCartLines } from './helpers/utils'
import { shippingAddressObject } from './helpers/addressParser'

const PinmapPro = () => {
  const {
    isPaying,
    steps,
    currentStep,
    setCurrentStep,
    ecommerceInfo,
    companyInfo,
    userInfo,
    setUserInfo,
    setPointsData,
    addressInfo,
    cartData
  } = useContext(DataContext)
  const { getCompanyInfo, getPoints } = useApiPinflag()
  const {
    iframeLoadedMagento,
    submitFormMagento,
    setInitialInformationMagento
  } = useMagento()
  const { setInitialInformationVtex } = useVtex()
  const { setInitialInformationShopify, checkUpdatedCart } =
    useShopify()
  const { iframeLoadedWoocommerce, setInitialInformationWoocommerce } =
    useWoocommerce()
  const { postTrackingEvent, createCheckoutLog } = useCmsAdapter()
  const [closePinmap, setClosePinmap] = useState(false)

  const handleStepClick = (stepData) => {
    if (stepData && !stepData.disabled && stepData.stepId !== currentStep) {
      setPointsData((prevState) => {
        prevState.localPointSelected = {}
        prevState.pinflagPointSelected = {}
        return prevState
      })
      setCurrentStep(stepData.stepId)
    }
  }

  const preloadUserInfo = () => {
    setUserInfo(prevState => ({
      ...prevState,
      ...getUserInfoLocalStorage()
    }))
  }

  const incomingMessageHandler = async (event) => {
    if (event.data.origin) {
      const { message, origin } = event.data
      switch (event.data.type) {
        case 'receive-cart-info':
          setInitialInformationMagento(message)
          setInitialInformationVtex(message)
          setInitialInformationShopify(message, origin)
          setInitialInformationWoocommerce(message)
          break
        case 'set-form-data-finished':
          submitFormMagento()
          break
        case 'close-pinmap':
          setClosePinmap(true)
          break
        case 'update-info-cart':
          checkUpdatedCart(message)
          break
        default:
          break
      }
    }
  }

  useEffect(() => {
    if (closePinmap && !isPaying && userInfo.email && cartData.lines.length) {
      const checkoutData = {
        email: userInfo.email,
        shippingAddress: addressInfo.userAddress
          ? shippingAddressObject(userInfo, addressInfo, companyInfo)
          : undefined,
        lineItems: abreviatedCartLines(cartData.lines)
      }
      createCheckoutLog(checkoutData)
    }
  }, [closePinmap])

  useEffect(() => {
    if (ecommerceInfo.cms && companyInfo.companyName) {
      postTrackingEvent('impression')
      if (ecommerceInfo.cms !== 'shopify') {
        getPoints()
      }
    }
  }, [ecommerceInfo.cms, companyInfo.companyName])

  // useEffect(() => {
  //   ecommerceInfo.checkoutId && companyInfo.pinflagApiKey && loadCheckout()
  // }, [ecommerceInfo.checkoutId, companyInfo.pinflagApiKey])

  useEffect(() => {
    window.addEventListener('message', incomingMessageHandler)
    iframeLoadedMagento()
    iframeLoadedWoocommerce()
    getCompanyInfo()
    preloadUserInfo()
    return () => {
      window.removeEventListener('message', incomingMessageHandler)
    }
  }, [])

  return (
    <div className='h-full overflow-y-scroll md:overflow-y-hidden pb-28 md:pb-0'>
      {!isPaying
        ? (
        <div className='md:w-[850px] md:h-full font-poppins'>
          <Header />
          <div className='md:hidden'>
            <Stepper
              className='mt-3 px-4 md:w-4/6'
              steps={steps}
              currentStep={currentStep}
              onStepClick={handleStepClick}
              secondaryColor={companyInfo.secondaryColor}
            />
          </div>
          <div className='hidden md:flex'>
            <DesktopStepper
              className='mt-5 md:mt-0 px-4 md:w-[480px] md:text-[11px] md:ml-5 md:mb-1'
              steps={steps}
              currentStep={currentStep}
              onStepClick={handleStepClick}
              primaryColor={companyInfo.primaryColor}
              secondaryColor={companyInfo.secondaryColor}
            />
          </div>
          {currentStep === 1 && <UserForm />}
          {currentStep === 2 && <ShippingOptionRenderer />}
        </div>
          )
        : (
        <FinalLoader />
          )}
    </div>
  )
}

export default PinmapPro
