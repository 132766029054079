import { BILLING_TYPE_STORES } from '../constants/stores'
import { BILLING_KEY_TRANSLATION, BILLING_KEY_TRANSLATION_STORES, DELIVERY_TYPE_TRANSLATION } from '../constants/translation'
import { camelToSnake } from './utils'

export const addressParser = (address) => {
  const addressParser = address.split(', ')
  const region = addressParser[2]
  const regionWithoutPostcode = region.slice(0, addressParser[2].length - 8)
  addressParser[2] = regionWithoutPostcode
  return addressParser
}

export const addressParserUnnormalized = (address) => {
  const addressParserUnnormalized = address.split(',')
  if (addressParserUnnormalized.length === 2) {
    return [addressParserUnnormalized[0], addressParserUnnormalized[1], '']
  } else {
    return addressParserUnnormalized.slice(0, 3)
  }
}

export const normalizeRegionName = (region) => {
  const normalizedRegionName = region
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace("'", '')
    .replace('Región del ', '')
    .replace('Región de ', '')
    .replace('Region de ', '')
    .replace('Region del ', '')
    .replace('Region ', '')
    .replace(/[" "][0-9]+/g, '')
    .replace('Biobio', 'Bio Bio')

  return normalizedRegionName
}

export const regionIdApi = {
  Tarapaca: '1',
  Antofagasta: '2',
  Atacama: '3',
  Coquimbo: '4',
  Valparaiso: '5',
  OHiggins: '6',
  Maule: '7',
  'Bio Bio': '8',
  Biobio: '8',
  'La Araucania': '9',
  'Los Lagos': '10',
  'Aysen del General Carlos Ibanez del Campo': '11',
  'Magallanes y de la Antartica Chilena': '12',
  'Metropolitana de Santiago': '13',
  'Los Rios': '14',
  'Arica y Parinacota': '15',
  Nuble: '16'
}

export const getRegionById = regionId => {
  return Object.keys(regionIdApi).filter(
    region => regionIdApi[region] === regionId
  )[0]
}

export const regionCode = {
  Tarapaca: 'TA',
  Antofagasta: 'AN',
  Atacama: 'AT',
  Coquimbo: 'CO',
  Valparaiso: 'VS',
  'Libertador General Bernardo OHiggins': 'LI',
  OHiggins: 'LI',
  Maule: 'ML',
  'Bio Bio': 'BI',
  Biobio: 'BI',
  'La Araucania': 'AR',
  'Los Lagos': 'LL',
  'Aysen del General Carlos Ibanez del Campo': 'AI',
  'Magallanes y de la Antartica Chilena': 'MA',
  'Metropolitana de Santiago': 'RM',
  'Los Rios': 'LR',
  'Arica y Parinacota': 'AP',
  Nuble: 'NB'
}

export const regionBillingCode = {
  TA: 'Tarapacá',
  AN: 'Antofagasta',
  AT: 'Atacama',
  CO: 'Coquimbo',
  VS: 'Valparaíso',
  LI: "Región de O'Higgins",
  ML: 'Maule',
  BI: 'Región del Bío Bío',
  AR: 'La Araucanía',
  LL: 'Los Lagos',
  AI: 'Aysén del General Carlos Ibáñez del Campo',
  MA: 'Magallanes y de la Antártica Chilena',
  RM: 'Metropolitana de Santiago',
  LR: 'Los Ríos',
  AP: 'Arica y Parinacota',
  NB: 'Región de Ñuble'
}

export const shippingAddressObject = (userInfo, addressInfo, companyInfo) => {
  const { companyName } = companyInfo
  const { names, rut, phone } = userInfo
  const {
    userAddress,
    price,
    deliveryType,
    district,
    aditionalInfo,
    region,
    serviceType,
    courier
  } = addressInfo

  const firstName = names.substring(0, names.indexOf(' '))
  const lastName = names.substring(names.indexOf(' ') + 1)
  const complementPrice = serviceType ? `${courier} - ${serviceType}` : 'standard'
  const address2 = `${price} - ${DELIVERY_TYPE_TRANSLATION[deliveryType]} - ${complementPrice}`

  const shippingAddress = {
    address1: userAddress,
    address2: address2 && '',
    city: district,
    company: companyName === 'SELFIE' ? rut : aditionalInfo,
    country: 'Chile',
    firstName,
    lastName,
    phone,
    province: regionCode[normalizeRegionName(region)],
    zip: companyName === 'SELFIE' ? aditionalInfo : rut
  }

  return shippingAddress
}

export const shippingAddressParser = shippingAddress => {
  const addressParsed = Object.keys(shippingAddress)
    .filter(
      key => shippingAddress[key] !== ''
    )
    .map(
      key =>
        `checkout[shipping_address][${camelToSnake(key)}]=${shippingAddress[key]
        }`
    )
    .join('&')

  return addressParsed !== '' ? `&${addressParsed}` : ''
}

export const billingUrlParser = (billingInfo, companyName) => {
  const url = Object.keys(billingInfo)
    .filter(
      key => billingInfo[key] !== ''
    )
    .map(
      key =>
        `attributes[${BILLING_KEY_TRANSLATION_STORES[companyName]
          ? BILLING_KEY_TRANSLATION_STORES[companyName][key]
          : BILLING_KEY_TRANSLATION[key]
        }]=${billingInfo[key]}`
    )
    .join('&')
  const prevUrl = BILLING_TYPE_STORES[companyName] || ''
  return prevUrl + url
}

export const billingInputParser = billingInfo => {
  const customAttributes = []
  Object.keys(billingInfo).forEach((key) => {
    customAttributes.push({
      key: BILLING_KEY_TRANSLATION[key],
      value: billingInfo[key]
    })
  })
  return { customAttributes }
}
