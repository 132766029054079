import { useContext } from 'react'
import { DataContext } from '../context/DataContext'
import { numberParser } from '../helpers/numberParser'
import { useCmsAdapter } from './useCmsAdapter'

export const useVtex = () => {
  const {
    addressInfo,
    ecommerceInfo,
    setCartData,
    setEcommerceInfo,
    userInfo,
    setUserInfo
  } = useContext(DataContext)
  const { ignoreProfileDataVtex } = useCmsAdapter()

  const setInitialInformationVtex = message => {
    const {
      cms,
      orderFormId,
      items,
      totals,
      currentUrl,
      clientData,
      loggedIn
    } = message
    if (cms !== 'vtex') return
    setEcommerceInfo(prevState => {
      return { ...prevState, cms, orderFormId, currentUrl, loggedIn }
    })
    if (clientData && loggedIn) {
      const names = `${clientData.firstName} ${clientData.lastName}`
      setUserInfo(prevState => {
        return {
          ...prevState,
          email: clientData.email,
          names: names.includes('null') ? '' : names,
          phone: clientData.phone,
          rut: clientData.document
        }
      })
    }

    const lines = []
    let itemsCount = 0
    items.forEach(item => {
      lines.push({
        id: `${item.uniqueId}`,
        productName: item.name,
        productImage: item.imageUrl,
        quantity: item.quantity,
        lineTotal: numberParser((item.price / 100) * item.quantity)
      })
      itemsCount += item.quantity
    })
    setCartData({
      lines,
      subtotal: totals.value / 100,
      total: totals.value / 100,
      shippingCost: 0,
      itemsCount
    })
    /* if (addressData?.street) {
      setAddressInfo(prevState => {
        return {
          ...prevState,
          userAddress: `${addressData.street} ${addressData.number}`,
          aditionalInfo: addressData.complement,
          region: addressData.state,
          district: addressData.neighborhood,
          deliveryType: addressData.addressType,
        }
      })
    } */
  }

  const saveUserInformationVtex = async (userData, billingData) => {
    if (ecommerceInfo.cms !== 'vtex') return

    try {
      if (!ecommerceInfo.loggedIn) {
        await ignoreProfileDataVtex(ecommerceInfo.orderFormId)
      }
    } catch (err) {
      console.log(err)
    }
    const firstname = userData.names.substring(0, userData.names.indexOf(' '))
    const lastname = userData.names.substring(userData.names.indexOf(' ') + 1)
    const userInformation = {
      email: userData.email,
      firstName: firstname,
      lastName: lastname,
      documentType: 'rutCHL',
      document: userData.rut,
      phone: userData.phone,
      isCorporate: false
    }
    if (billingData) {
      const billingInformation = {
        corporateDocument: billingData.corporateRut,
        corporateName: billingData.corporateName,
        corporatePhone: billingData.corporatePhone,
        isCorporate: true,
        tradeName: billingData.category,
        stateInscription: billingData.corporatePhone
      }
      Object.assign(userInformation, billingInformation)
    }
    window.parent.postMessage(
      {
        type: 'save-user-information',
        origin: 'pinflag-checkout-iframe',
        message: { userInformation, orderFormId: ecommerceInfo.orderFormId }
      },
      '*'
    )
  }

  const saveShippingInformationVtex = () => {
    if (ecommerceInfo.cms !== 'vtex') return
    const street = addressInfo.userAddress.split(' ').slice(0, -1).join(' ')
    const number = addressInfo.userAddress.split(' ').pop()
    const priceLength = `${addressInfo.price}`.length
    const postcode = '0'.repeat(7 - priceLength) + `${addressInfo.price}`
    const addressInformation = {
      postalCode: addressInfo.price === 0 ? '0030001' : postcode,
      state: addressInfo.region,
      country: 'CHL',
      street,
      number,
      neighborhood: addressInfo.district,
      complement: addressInfo.aditionalInfo,
      addressType: addressInfo.deliveryType,
      receiverName: userInfo.names
    }
    window.parent.postMessage(
      {
        type: 'save-address-information',
        origin: 'pinflag-checkout-iframe',
        message: { addressInformation, orderFormId: ecommerceInfo.orderFormId }
      },
      '*'
    )
  }

  return {
    setInitialInformationVtex,
    saveUserInformationVtex,
    saveShippingInformationVtex
  }
}
