import React, { useContext, useEffect, useState } from 'react'
import { ShippingMethodSelector, Spinner } from '@pinflag/pinflag-ui-kit'
import { DataContext } from '../../context/DataContext'
import { useApiPinflag } from '../../hook/useApiPinflag'
import AddressTypeInputSelector from './AddressTypeInputSelector'
import PickupMap from './PickupMap'
import { useCmsAdapter } from '../../hook/useCmsAdapter'

const ShippingOptionRenderer = () => {
  const [isLoading, setIsLoading] = useState(true)
  const {
    steps,
    setSteps,
    pointsData,
    selectedShippingMethod,
    setSelectedShippingMethod,
    setIsPickup,
    setIsPaying,
    companyInfo
  } = useContext(DataContext)
  const { postTrackingEvent } = useCmsAdapter()
  const { primaryColor, secondaryColor, companyName } = companyInfo
  const { getPoints } = useApiPinflag()
  const { localPoints, pinflagPoints } = pointsData
  const areDisabled = {
    localPickup: !localPoints.length,
    cnc: !pinflagPoints.length,
    homeDelivery: false
  }
  const badge = {
    localPickup: companyInfo.storePickupPriceLabel,
    cnc: companyInfo.ccDiscountPercentageLabel,
    homeDelivery: ''
  }
  const buttonsTitle = {
    localPickup: 'Retiro en ' + companyInfo.storePickupTypeLabel ? companyInfo.storePickupTypeLabel : 'Tienda',
    cnc: 'Retiro en Punto',
    homeDelivery: 'Despacho a Domicilio'
  }

  const handleClick = option => {
    const updatedSteps = [...steps]
    updatedSteps[0].completed = true
    updatedSteps[1].disabled = false
    updatedSteps[1].subSteps[0].completed = true
    setSteps(updatedSteps)
    setSelectedShippingMethod(option)
    postTrackingEvent(`click - ${option}`, {
      currentCheckoutSubstep: '1',
      previousCheckoutStep: '1'
    })
  }

  const redirection = () => {
    const updatedSteps = [...steps]
    updatedSteps[0].completed = true
    updatedSteps[1].disabled = false
    updatedSteps[1].subSteps[0].completed = true
    setSteps(updatedSteps)
    setSelectedShippingMethod('home-delivery')
    postTrackingEvent('click - home-delivery', {
      currentCheckoutSubstep: '1',
      previousCheckoutStep: '1'
    })
  }

  useEffect(() => {
    setIsPickup(false)
    postTrackingEvent('impression', {
      currentCheckoutSubstep: '1',
      previousCheckoutStep: '1'
    })
    if (!localPoints.length && !pinflagPoints.length) {
      getPoints(setIsLoading)
    } else {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (!isLoading && areDisabled.cnc && areDisabled.localPickup) {
      redirection()
    }
  }, [isLoading])

  if (isLoading) {
    return (
      <div className='full_width_spinner md:w-[537px]'>
        <div className='md:flex md:justify-center'>
          <Spinner width={44} />
        </div>
      </div>
    )
  }

  return (
    <>
      {!selectedShippingMethod
        ? (
          <ShippingMethodSelector
            areDisabled={areDisabled}
            handleButtonClick={handleClick}
            companyName={companyName}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            badge={badge}
            isCncDisabled={companyName === 'Safety Store B2B'}
            isLocalPickupDisabled={
              companyName === 'Safety Store B2B' ||
              companyName === 'SELFIE'
            }
            buttonsTitle={buttonsTitle}
          />
          )
        : selectedShippingMethod === 'home-delivery'
          ? (
            <AddressTypeInputSelector setIsPaying={setIsPaying} />
            )
          : (
            <PickupMap />
            )}
    </>
  )
}

export default ShippingOptionRenderer
