import { numberParser } from './numberParser'
import CryptoJS from 'crypto-js'

export const transformCart = items => {
  const lines = []
  items.forEach(item => {
    lines.push({
      id: `${item.item_id}`,
      productName: item.name,
      productImage: item.thumbnail,
      quantity: parseInt(item.qty),
      lineTotal: numberParser(item.price * item.qty)
    })
  })
  return lines
}

export const transformCartTotals = data => {
  return {
    subtotal: data.base_grand_total,
    total: data.grand_total,
    shippingCost: data.shipping_discount_amount,
    itemsCount: data.items_qty
  }
}

export const scrollToTop = () => {
  const scrollers = document.getElementsByClassName('list-item-div')
  const arrayScrollers = [...scrollers]
  arrayScrollers.forEach((scroller) => {
    scroller.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  })
}

export const scrollToBottom = () => {
  const scroll = document.getElementsByClassName('div-user-form')
  scroll[0].scrollTo({
    top: scroll[0].scrollHeight,
    behavior: 'smooth'
  })
}

export const totalPriceParser = (items) => {
  const totalPrice = items?.reduce((a, b) => { return { final_line_price: a.final_line_price + b.final_line_price } })
  return totalPrice.final_line_price / 100
}

export const abreviatedCartLines = (cartLines) => {
  if (!cartLines || Object.keys(cartLines).length === 0) return []
  return cartLines.map(line => {
    return { variantId: `${btoa(`gid://shopify/ProductVariant/${line?.variantId}`)}`, quantity: line.quantity }
  })
}

export const onlyLetters = (value) => {
  const result = value.replace(/[^a-z \-àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸ`'´]/gi, '')
  return result
}

export const onlyNumbers = (value) => {
  const result = value.replace(/[^\d]/g, '')
  return result
}

export const onlySomeNumbers = (value) => {
  const result = value.match(/\d{10,}/g)
  if (result) {
    return result[0].match(/\d{9}/)[0]
  }
  return value
}

export const onlySomeLetters = (value, quantity) => {
  const result = value.match(new RegExp(`[A-Za-z\\d\\s\\S]{${quantity + 1},}`, 'g'))
  if (result) {
    return result[0].match(new RegExp(`[A-Za-z\\d\\s\\S]{${quantity}}`))[0]
  }
  return value
}

export const camelToSnake = (str) => (str.replace(/([A-Z])/g, (match) => `_${match.toLowerCase()}`))

export const itemsUrlParser = (items) => {
  return items
    .map((item) => `${item.variantId}:${item.quantity}`)
    .join(',')
}

export const setServiceAndShippingcost = (selectedService, setterService, setterCart, cartData) => {
  setterService(selectedService)
  setterCart(prevState => {
    const prevShippingCost = prevState.shippingCost || 0
    return {
      ...prevState,
      shippingCost: selectedService.price,
      total: (cartData.total || 0) + selectedService.price - prevShippingCost
    }
  })
}

export const encryptToken = (token, key) => {
  const iv = CryptoJS.lib.WordArray.random(16)

  const encrypted = CryptoJS.AES.encrypt(token, key, { iv })

  const combined = iv.toString() + encrypted.toString()

  return combined
}

export const decryptToken = (encrypted, key) => {
  const iv = CryptoJS.enc.Hex.parse(encrypted.slice(0, 32))

  const encryptedToken = encrypted.slice(32)

  const decrypted = CryptoJS.AES.decrypt(encryptedToken, key, { iv })

  return decrypted.toString(CryptoJS.enc.Utf8)
}
