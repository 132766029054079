import React, { useContext, useEffect, useState } from 'react'
import { DataContext } from '../context/DataContext'
import { useMagento } from '../hook/useMagento'
import { useShopify } from '../hook/useShopify'
import { useVtex } from '../hook/useVtex'
import { useWoocommerce } from '../hook/useWoocommerce'
import { Icon, Image, ProgressBar } from '@pinflag/pinflag-ui-kit'

const FinalLoader = () => {
  const { shippingPostMessageMagento } = useMagento()
  const { saveShippingInformationVtex } = useVtex()
  const { redirectPermalink, completeCheckoutLog } = useShopify()
  const { shippingPostMessageWoocommerce } = useWoocommerce()
  const { companyInfo, loaderInfo, userInfo } = useContext(DataContext)
  const [progressTime, setProgressTime] = useState(2)
  const { imageUrl, backgroundColor, body, bodyColor, title } =
    loaderInfo[0]

  useEffect(() => {
    shippingPostMessageWoocommerce()
    shippingPostMessageMagento()
    saveShippingInformationVtex()
    redirectPermalink()
    completeCheckoutLog(userInfo)
    // saveShippingInformationShopify()
  }, [])

  const step = 32
  const interval = 5000
  const maxProgress = 98
  const checkoutSteps = { user: 'Cargando tus datos personales', truck: 'Seleccionando envío', 'credit-card': 'Preparando pasarela de pago' }

  useEffect(() => {
    const updateProgress = () => {
      setProgressTime(progressTime + step)
    }
    if (progressTime < maxProgress) {
      setTimeout(updateProgress, interval)
    }
  }, [progressTime])

  return (
    <>
      {companyInfo.companyName === ''
        ? (
          <div
            className='h-screen flex flex-col md:flex-row justify-center items-center border-2 md:py-8'
            style={{ backgroundColor }}
          >
            <div className='w-full h-3/6 md:h-full md:px-8'>
              <Image imagePath={imageUrl} className='h-full w-full md:rounded-lg' />
            </div>

            <div className='w-full flex flex-col justify-between items-center h-full'>
              <div className='flex flex-col justify-evenly items-start h-3/4 md:w-3/4 px-6 md:px-0'>
                {companyInfo.logoUrl && <img
                  className='h-16'
                  src={companyInfo.logoUrl}
                  alt='ecommerce-logo'
                />}
                <div>
                  <h1 className='font-dm_sans font-bold text-lg md:text-xl text-left' style={{ color: bodyColor }}>
                    {title}
                  </h1>
                  <p className='text-sm md:text-lg font-dm_sans opacity-80 mt-4' style={{ color: bodyColor }}>
                    {body}
                  </p>
                </div>

              </div>
              <div className='w-full flex flex-col px-6 mb-6 md:px-4 md:mb-0'>
                <div className='content'>
                  {Object.keys(checkoutSteps).map((key, index) => (
                    <div key={index} className='rotatingText'>
                      <div className='flex itemb-4 ms-center'>
                        <Icon
                          name={key}
                          width={18}
                          customStyle={{ fill: bodyColor }}
                        />
                        <p
                          className='text-sm md:text-base font-dm_sans opacity-80 ml-3'
                          style={{ color: bodyColor }}
                        >
                          {checkoutSteps[key]}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <ProgressBar bgcolor={bodyColor} progress={progressTime} />
              </div>

            </div>
          </div>
          )
        : (
          <div className='h-screen flex justify-center items-center border-2'>
            <div className='flex flex-col items-center'>
              <div>
                <img
                  className='h-12'
                  src={companyInfo.logoUrl}
                  alt='ecommerce-logo'
                />
              </div>
              <p className='font-bold text-sm text-slate-700 font-sans'>
                Cargando tu información
              </p>
              <div className='relative text-center h-16 w-full'>
                <div className='absolute top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4'>
                  <div className='lds-dual-ring'></div>
                </div>
              </div>
              <div className='flex'>
                <p className='text-sm flex font-sans'>
                  Powered by{' '}
                  <a
                    href='https://pinflag.cl'
                    target='_blank'
                    style={{ color: 'black', textDecoration: 'none' }}
                    rel='noreferrer'
                  >
                    <span className='font-bold'>&nbsp;Pinflag</span>
                  </a>
                </p>
              </div>
            </div>
          </div>
          )}
    </>
  )
}

export default FinalLoader
