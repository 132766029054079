import React, { useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { Input, Button, Icon, SectionTitle, Select } from '@pinflag/pinflag-ui-kit'
import { useForm } from 'react-hook-form'
import classNames from 'classnames'
import { InputValidator } from '../helpers/Validator'
import { DataContext } from '../context/DataContext'
import { prettifyRut } from 'react-rut-formatter'
import { useVtex } from '../hook/useVtex'
import { useCmsAdapter } from '../hook/useCmsAdapter'
import { scrollToBottom, onlyLetters, onlyNumbers, abreviatedCartLines, onlySomeNumbers } from '../helpers/utils'
import { setUserInfoLocalStorage } from '../helpers/localStorage'
import { regionBillingCode } from '../helpers/addressParser'
import { gaCompletedUserInfoPostMessage } from '../helpers/gaEventsHelper'

const UserForm = ({ className }) => {
  const {
    userInfo: userData,
    billingInfo: billingData,
    setIsPickup,
    setUserInfo,
    steps,
    setSteps,
    setCurrentStep,
    setBillingInfo,
    ecommerceInfo,
    companyInfo,
    cartData
  } = useContext(DataContext)
  const { primaryColor, secondaryColor, companyName, useBillingData } = companyInfo
  const { postTrackingEvent, createCheckoutLog } = useCmsAdapter()
  const { saveUserInformationVtex } = useVtex()
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'all'
  })

  const isVtex = ecommerceInfo.cms === 'vtex'
  const isBillingEnable = isVtex || useBillingData
  const [saveData, setSaveData] = useState(userData.saveData)
  const [customErrors, setCustomErrors] = useState({})
  const [loadingButton, setLoadingButton] = useState(false)
  const [isBilling, setIsBilling] = useState(
    !!billingData.corporateName
  )

  const userInfo = {
    email: {
      ...register('email', {
        required: 'Campo requerido',
        validate: value =>
          InputValidator.isEmailValid(value) || 'Ingresa un correo válido',
        onBlur: () =>
          setCustomErrors({ ...customErrors, email: errors?.email })
      }),
      label: 'Correo electrónico'
    },
    names: {
      ...register('names', {
        required: 'Campo requerido',
        validate: value =>
          InputValidator.isNameValid(value) || 'Nombre y/o Apellido inválidos',
        onBlur: () =>
          setCustomErrors({ ...customErrors, names: errors?.names }),
        onChange: e => setValue('names', onlyLetters(e.target.value))
      }),
      label: 'Nombre y Apellido '
    },
    phone: {
      ...register('phone', {
        required: 'Campo requerido',
        validate: value =>
          InputValidator.isPhoneValid(value, ecommerceInfo.cms) || 'Ingresa un número válido',
        onBlur: () =>
          setCustomErrors({ ...customErrors, phone: errors?.phone }),
        onChange: e => setValue('phone', onlySomeNumbers(onlyNumbers(e.target.value)))
      }),
      label: 'Teléfono'
    },
    rut: {
      ...register('rut', {
        required: 'Campo requerido',
        validate: value => InputValidator.isRutValid(value) || 'Rut inválido',
        onBlur: () => setCustomErrors({ ...customErrors, rut: errors?.rut }),
        onChange: e => setValue('rut', prettifyRut(e.target.value))
      }),
      label: 'Rut'
    },
    saveData: { ...register('saveData', { required: companyInfo.companyName === 'SCJJSPA' ? 'Debes aceptar términos y condiciones' : false }) }
  }

  const billingInfo = {
    corporateName: {
      ...register('corporateName', {
        required: isBilling ? 'Campo requerido' : false,
        onBlur: () =>
          setCustomErrors({
            ...customErrors,
            corporateName: errors?.corporateName
          })
      }),
      label: 'Razón Social'
    },
    corporateRut: {
      ...register('corporateRut', {
        required: isBilling ? 'Campo requerido' : false,
        validate: value =>
          InputValidator.isRutValid(value) || !isBilling || 'Rut inválido',
        onBlur: () =>
          setCustomErrors({
            ...customErrors,
            corporateRut: errors?.corporateRut
          }),
        onChange: e => setValue('corporateRut', prettifyRut(e.target.value))
      }),
      label: 'Rut'
    },
    category: {
      ...register('category', {
        required: isBilling ? 'Campo requerido' : false,
        onBlur: () =>
          setCustomErrors({ ...customErrors, category: errors?.category })
      }),
      label: 'Giro'
    },
    corporatePhone: {
      ...register('corporatePhone', {
        required: isBilling ? 'Campo requerido' : false,
        validate: value =>
          InputValidator.isPhoneValid(value) ||
          !isBilling ||
          'Ingrese un número con 9 dígitos',
        onBlur: () =>
          setCustomErrors({
            ...customErrors,
            corporatePhone: errors?.corporatePhone
          }),
        onChange: e => setValue('corporatePhone', onlySomeNumbers(onlyNumbers(e.target.value)))
      }),
      label: 'Teléfono'
    }
  }

  const shopifyExtraBillingInfo = {
    regionCode: register('regionCode', { required: isBilling ? 'Campo requerido' : false }),
    district: {
      ...register('district', {
        required: isBilling && !isVtex ? 'Campo requerido' : false,
        onBlur: () =>
          setCustomErrors({
            ...customErrors,
            district: errors?.district
          })
      }),
      label: 'Comuna'
    },
    address: {
      ...register('address', {
        required: isBilling && !isVtex ? 'Campo requerido' : false,
        onBlur: () =>
          setCustomErrors({
            ...customErrors,
            address: errors?.address
          })
      }),
      label: 'Dirección'
    }
  }

  isBillingEnable && !isVtex && Object.assign(billingInfo, shopifyExtraBillingInfo)

  const containerClasses = classNames(
    'flex flex-col items-between justify-between h-full md:w-[495px] md:text-[11px]',
    className
  )

  const handleCheckbox = () => {
    setValue('saveData', !saveData)
    setSaveData(!saveData)
  }

  const handleRegionChange = data => {
    setValue('regionCode', data.value)
  }

  useEffect(() => {
    setValue('names', userData.names)
    setValue('email', userData.email)
    setValue('rut', userData.rut)
    setValue('phone', userData.phone)
    setValue('saveData', userData.saveData)
    setValue('corporateName', billingData.corporateName)
    setValue('corporateRut', billingData.corporateRut)
    setValue('category', billingData.category)
    setValue('corporatePhone', billingData.corporatePhone)
    isBillingEnable && !isVtex && setValue('address', billingData.address)
    isBillingEnable && !isVtex && setValue('district', billingData.district)
  }, [setValue, userData, billingData])

  useEffect(() => {
    isBilling && scrollToBottom()
  }, [isBilling])

  useEffect(() => {
    setIsPickup(false)
  }, [setIsPickup])

  const handleUserFormSubmit = async formData => {
    const {
      names,
      email,
      rut,
      phone,
      saveData,
      category,
      corporateName,
      corporatePhone,
      corporateRut,
      address,
      district,
      regionCode
    } = formData
    postTrackingEvent('click - submit', { userEmail: email })
    const userData = { names: names.trim(), email, rut, phone, saveData }
    const billingData = {
      corporateRut,
      corporateName,
      category,
      regionCode,
      district,
      address,
      corporatePhone
    }
    gaCompletedUserInfoPostMessage()
    window.parent.postMessage(
      {
        type: 'user-information',
        origin: 'pinflag-shopify-pinmap-pro',
        message: { names, email, rut, phone }
      },
      '*'
    )
    setLoadingButton(true)
    /* const wrongEmail = await handleUserInformationShopify(userData)
    if (wrongEmail) {
      const emailError = {
        type: 'validate',
        message: 'Ingrese un dominio que exista'
      }
      setCustomErrors(prevState => {
        return { ...prevState, email: emailError }
      })
      setError('email', emailError)
    } else {
      setUserInfo(userData)
      isBilling && setBillingInfo(billingData)
      const updatedSteps = [...steps]
      updatedSteps[0].completed = true
      updatedSteps[1].disabled = false
      setSteps(updatedSteps)
      setCurrentStep(2)
      saveUserInformationVtex(userData, isBilling ? billingData : false)
    } */
    const checkoutData = {
      email,
      lineItems: abreviatedCartLines(cartData.lines)
    }
    createCheckoutLog(checkoutData)
    setUserInfo(userData)
    isBilling && setBillingInfo(billingData)
    const updatedSteps = [...steps]
    updatedSteps[0].completed = true
    updatedSteps[1].disabled = false
    setSteps(updatedSteps)
    setCurrentStep(2)
    setUserInfoLocalStorage(userData)
    saveUserInformationVtex(userData, isBilling ? billingData : false)
    setLoadingButton(false)
  }

  return (
    <div className='grow h-full md:w-[537px] bg-slate-100 pt-2'>
      <div className='text-xs text-slate-500 pt-2 flex w-[100vw] justify-center md:hidden'>
        Completa tus datos para continuar con la compra.
      </div>
      <form
        onSubmit={handleSubmit(handleUserFormSubmit)}
        className={containerClasses}
        autoComplete='off'
        id='user-info-form'
      >
        <div
          className={`div-user-form ${isBillingEnable ? 'h-[382px]' : 'h-[352px]'
            } bg-white md:w-full md:rounded-lg p-4 mx-5 md:mt-3 md:p-0 md:px-[21px] md:h-[320px] overflow-auto`}
        >
          <div className='flex items-center justify-between pl-2 pb-3 md:pb-2 md:my-1 md:mt-3 text-sm font-medium md:text-[11px] md:font-bold'>
            <SectionTitle
              title='Datos de identificación'
              iconName='user'
              primaryColor={primaryColor}
              iconCustomStyle={{ fill: 'transparent', stroke: 'white' }}
            />
            <div className={`${isBillingEnable ? 'md:flex' : ''} hidden`}>
              <Button
                label={isBilling ? 'Cancelar' : 'Sumar datos de Facturación'}
                LeftIcon={
                  <Icon name='edit' className='mr-1 text-sm' width={9} />
                }
                onClick={() => {
                  setIsBilling(!isBilling)
                }}
                className='bg-slate-100 px-4 py-1 rounded-2xl text-[11px] font-light flex items-center h-[23px]'
                testId='billing-data'
              />
            </div>
          </div>
          <div className={'flex flex-wrap justify-between'}>
            {Object.keys(userInfo).map(key => {
              if (key === 'saveData') return false
              return (
                <Input
                  key={key}
                  {...userInfo[key]}
                  containerClass={`mb-3 md:mb-1.5 bg-white border md:bg-white ${key === 'phone'
                    ? 'md:w-7/12'
                    : key === 'rut'
                      ? 'md:w-2/5'
                      : ''
                    }`}
                  innerRef={userInfo[key].ref}
                  type={
                    key === 'email' ? 'email' : key === 'phone' ? 'tel' : 'text'
                  }
                  errors={errors[key] || watch(key) === ''}
                  customErrors={customErrors[key]}
                  secondaryColor={secondaryColor}
                  testId={key}
                />
              )
            })}
          </div>

          <div
            className={`${isBilling
              ? 'justify-between my-2'
              : 'justify-end mt-3 mb-0 md:hidden'
              } flex row items-center`}
          >
            <SectionTitle
              title='Datos de facturación'
              iconName='apartment'
              primaryColor={primaryColor}
              className={`${isBilling ? 'flex' : 'hidden'
                } items-center pl-2 text-sm font-medium md:text-[11px] md:font-bold`}
            />

            <div className={`${isBillingEnable ? '' : 'hidden'} md:hidden`}>
              <Button
                label={isBilling ? 'Cancelar' : 'Sumar datos de Facturación'}
                LeftIcon={
                  <Icon name='edit' className='mr-1 text-sm' width={9} />
                }
                onClick={() => {
                  setIsBilling(!isBilling)
                }}
                className='bg-slate-100 px-4 py-1 rounded-2xl text-[11px] font-light flex items-center h-[23px]'
                testId='billing-data'
              />
            </div>
          </div>
          <div
            className={`${isBilling ? 'block' : 'hidden'
              } flex flex-wrap justify-between`}
          >
            {Object.keys(billingInfo).map(key => {
              if (key === 'regionCode') {
                return (
                  <div key={key} className='w-full mb-3 md:mb-1.5 md:w-7/12'>
                    <Select
                      label='Región'
                      options={Object.keys(regionBillingCode).map(regionCode => ({
                        label: regionBillingCode[regionCode],
                        value: regionCode
                      }))}
                      onChange={handleRegionChange}
                      errors={errors?.regionCode}
                      defaultValue={{
                        label: watch('regionCode')
                          ? regionBillingCode[watch('regionCode')]
                          : '',
                        value: watch('regionCode')
                      }}
                      testId="region-select"
                      optionsContainerClass='max-h-[100px] bg-white'
                    />
                  </div>
                )
              }
              return (
                <Input
                  key={key}
                  {...billingInfo[key]}
                  containerClass={`mb-3 md:mb-1.5 bg-white border md:bg-white ${key === 'corporateName' || key === 'category'
                    ? 'md:w-7/12'
                    : key === 'corporateRut' || key === 'corporatePhone' || key === 'district'
                      ? 'md:w-2/5'
                      : ''
                    }`}
                  innerRef={billingInfo[key].ref}
                  type={key === 'corporatePhone' ? 'tel' : 'text'}
                  errors={errors[key] || watch(key) === ''}
                  customErrors={customErrors[key]}
                  secondaryColor={secondaryColor}
                  testId={key}
                />
              )
            })}
          </div>
          <div className='hidden md:flex md:items-start  md:flex-row md:justify-between'>
            <div className='flex flex-col md:pl-3 w-full items-start'>
              <div className={`flex pt-5 justify-center terms-checkbox ${companyName === 'SELFIE' ? '' : 'hidden'}`}>
                <div className='text-sm'>
                  &nbsp; ¿Tienes cuenta? Ingresa<a className="no-underline pl-1" target="_blank" rel="noreferrer" href="https://www.selfie.cl/account/login?return_url=%2Faccount"><b>acá</b></a>
                </div>
              </div>
              <div className={`flex pt-3 justify-center terms-checkbox ${companyName === 'SCJJSPA' ? '' : 'hidden'}`}>
                <input
                  type='checkbox'
                  name='saveData'
                  onChange={saveData => handleCheckbox(saveData)}
                  checked={saveData}
                  onInvalid={(e) => e.target.setCustomValidity('Debes aceptar los T&C para continuar')}
                  onInput={(e) => e.target.setCustomValidity('')}
                  data-testid="term-check"
                />
                <div className='text-xs flex pl-1 font-inter'>
                  <Icon name='flash' className='fill-slate-400 w-[9px] mr-1 hidden' />{' '}
                  Acepto los <a className="no-underline pl-1" target="_blank" rel="noreferrer" href="https://japijane.cl/pages/despachos"> <b>términos y condiciones</b></a>
                </div>
              </div>
              {errors.saveData && (errors.saveData || !saveData) && <span className='text-red-400 text-xs pl-4'>Debes aceptar T&C para continuar</span>}
            </div>
            <div className='flex justify-center pb-2'>
              <Button
                role='submit'
                className='bg-black text-white text-base rounded-3xl mt-3 mb-2 pb-1 justify-center disabled:bg-slate-100 disabled:text-gray-500 md:h-[37px] md:w-[149px] md:px-[15px] md:my-0.5'
                label='Continuar'
                disabled={Object.keys(watch()).some(
                  key =>
                    (watch(key) === '' &&
                      !Object.keys(billingInfo).includes(key)) ||
                    (isBilling && watch(key) === '')
                )}
                loading={loadingButton}
                customStyle={{ backgroundColor: secondaryColor }}
                testId='go-delivery'
              />
            </div>
          </div>
        </div>
        <div className='mt-9 w-full bg-white flex flex-col justify-between'>
          <div className='md:hidden'>
            <div className='flex flex-col'>
              <div className={`flex pt-5 justify-center terms-checkbox ${companyName === 'SCJJSPA' ? '' : 'hidden'}`}>
                <input
                  type='checkbox'
                  name='saveData'
                  onChange={saveData => handleCheckbox(saveData)}
                  checked={saveData}
                  onInvalid={(e) => e.target.setCustomValidity('Debes aceptar los T&C para continuar')}
                  onInput={(e) => e.target.setCustomValidity('')}
                  data-testid="term-check"
                />
                <div className='text-sm'>
                  &nbsp; Acepto los <a className="no-underline pl-1" target="_blank" rel="noreferrer" href="https://japijane.cl/pages/despachos"> <b>términos y condiciones</b></a>
                </div>
              </div>
              <div className={`flex pt-5 justify-center terms-checkbox ${companyName === 'SELFIE' ? '' : 'hidden'}`}>
                <div className='text-sm'>
                  &nbsp; ¿Tienes cuenta? Ingresa<a className="no-underline pl-1" target="_blank" rel="noreferrer" href="https://www.selfie.cl/account/login?return_url=%2Faccount"><b>acá</b></a>
                </div>
              </div>
            </div>
            <div className='flex justify-center'>
              <Button
                role='submit'
                className='h-[3rem] bg-black text-white text-base rounded-3xl mt-4 w-[90vw] justify-center disabled:bg-slate-100 disabled:text-gray-500'
                label='Continuar'
                disabled={Object.keys(watch()).some(
                  key =>
                    (watch(key) === '' &&
                      !Object.keys(billingInfo).includes(key)) ||
                    (isBilling && watch(key) === '')
                )}
                loading={loadingButton}
                customStyle={{ backgroundColor: secondaryColor }}
                testId='go-delivery'
              />
            </div>
            <div className='md:hidden flex justify-center pt-2 text-sm text-slate-300'>
              100% Seguro + Rápido y Facil. Powered by
              <a
                href='https://pinflag.cl'
                target='_blank'
                style={{ textDecoration: 'none' }}
                rel='noreferrer'
              >
                <span className='font-bold'>&nbsp;Pinflag</span>
              </a>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

UserForm.propTypes = {
  className: PropTypes.string
}

export default UserForm
