export const isLocalStorageAvailable = () => {
  const test = 'test'
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

export const companyInfoAvailable = (pinflagApiKey) => {
  try {
    const companiesInformation = localStorage.getItem('companiesInformation')
    const companiesObject = JSON.parse(companiesInformation)

    if (!companiesObject[pinflagApiKey]) return false

    const { createdAt, ...companyInfo } = companiesObject[pinflagApiKey]

    if (!createdAt) return false

    const dateParsed = Date.parse(createdAt)
    const now = new Date()
    const diff = Math.abs(now - dateParsed) / (1000 * 60)
    return diff < 10 ? companyInfo : false
  } catch (e) {
    return false
  }
}

export const setCompanyLocalStorage = (companyInfo) => {
  try {
    const { pinflagApiKey } = companyInfo

    const companiesInformation = localStorage.getItem('companiesInformation')
    const companiesObject = JSON.parse(companiesInformation) || {}

    Object.assign(companyInfo, { createdAt: new Date() })

    companiesObject[pinflagApiKey] = companyInfo
    const companiesInformationParsed = JSON.stringify(companiesObject)

    localStorage.setItem('companiesInformation', companiesInformationParsed)
  } catch (e) {
    console.log('error', e)
  }
}

export const setUserInfoLocalStorage = (userInfo) => {
  try {
    Object.assign(userInfo, { createdAt: new Date() })

    const userInfoParsed = JSON.stringify(userInfo)
    localStorage.setItem('userInformation', userInfoParsed)
  } catch (e) {
    console.log('error', e)
  }
}

export const getUserInfoLocalStorage = () => {
  try {
    const userInformation = localStorage.getItem('userInformation')

    if (!userInformation) return {}

    const userObject = JSON.parse(userInformation)
    const { createdAt, ...userInfo } = userObject

    const dateParsed = Date.parse(createdAt)
    const now = new Date()
    const diff = Math.abs(now - dateParsed) / (1000 * 60 * 60)
    return diff < 24 ? userInfo : {}
  } catch (error) {
    return {}
  }
}
