import axios from 'axios'
import { useContext } from 'react'
import { CMS_ADAPTER_URL_DEV } from '../constants/url'
import { DataContext } from '../context/DataContext'
import cmsInstance from '../helpers/cmsInstance'

export const useCmsAdapter = () => {
  const { userInfo, currentStep, ecommerceInfo, companyInfo } =
    useContext(DataContext)
  const { pinflagApiKey, companyName } = companyInfo

  const postTrackingEvent = (eventType, body) => {
    cmsInstance
      .post('/tracking/event', {
        storeName: companyName,
        userEmail: userInfo.email,
        currentUrl: ecommerceInfo.currentUrl,
        currentCheckoutStep: `${currentStep}`,
        currentCheckoutSubstep: '',
        previousCheckoutStep: '',
        previousCheckoutSubstep: '',
        eventType,
        ...body
      })
      .catch(err => console.error(err))
  }

  const getVariantLocations = (variantId) => {
    return cmsInstance.post('/shopify/locations', {
      pinflagApiKey,
      variantId: `gid://shopify/ProductVariant/${variantId}`
    })
  }

  const getCheckout = checkoutId => {
    return cmsInstance.get(
      `/shopify/checkout?pinflagApiKey=${pinflagApiKey}&checkoutId=${checkoutId}`
    )
  }

  const createCheckout = input => {
    return cmsInstance.post('/shopify/checkout', {
      input,
      pinflagApiKey
    })
  }

  const updateEmailCheckout = (checkoutId, email) => {
    return cmsInstance.post('/shopify/checkout/update-email', {
      checkoutId,
      email,
      pinflagApiKey
    })
  }

  const setItemsCheckout = (checkoutId, oldLineItemsId, newLineItems) => {
    return cmsInstance.post('/shopify/checkout/set-items', {
      checkoutId,
      oldLineItemsId,
      newLineItems,
      pinflagApiKey
    })
  }

  const updateAddressCheckout = (checkoutId, address) => {
    return cmsInstance.post('/shopify/checkout/update-address', {
      checkoutId,
      address,
      pinflagApiKey
    })
  }

  const getShippingRates = checkoutId => {
    return cmsInstance.get(
      `/shopify/checkout/get-shipping-rates?pinflagApiKey=${pinflagApiKey}&checkoutId=${checkoutId}`
    )
  }

  const setShippingRate = (checkoutId, shippingRateHandle) => {
    return cmsInstance.post('/shopify/checkout/set-shipping-rate', {
      checkoutId,
      shippingRateHandle,
      pinflagApiKey
    })
  }

  const ignoreProfileDataVtex = orderFormId => {
    return cmsInstance.post('/vtex/order/ignore-personal-data', {
      orderFormId,
      pinflagApiKey
    })
  }

  const getPaymentUrl = permalink => {
    return axios.post(`${CMS_ADAPTER_URL_DEV}/shopify/scrapper`, {
      url: permalink
    }, {
      headers: { 'x-api-key': '$2a$10$kovsnhiKDrK1nXuKgsdGk.AQsto6ec8EypOpjfx1ro3Q2bUPXSPbu' }
    })
  }

  const createCheckoutLog = (checkoutData) => {
    return cmsInstance.post('/shopify/checkout-log', {
      checkoutData,
      pinflagApiKey
    })
  }

  const deleteCheckoutLog = (email) => {
    return cmsInstance.delete(`/shopify/checkout-log?email=${email}&pinflagApiKey=${pinflagApiKey}`)
  }

  const availableShippingRates = (input) => {
    return cmsInstance.post('/shopify/available-shipping-rates', {
      input,
      pinflagApiKey
    })
  }

  return {
    postTrackingEvent,
    getVariantLocations,
    getCheckout,
    createCheckout,
    updateEmailCheckout,
    setItemsCheckout,
    updateAddressCheckout,
    getShippingRates,
    setShippingRate,
    ignoreProfileDataVtex,
    getPaymentUrl,
    createCheckoutLog,
    deleteCheckoutLog,
    availableShippingRates
  }
}
